export type OptionsValue<T> = {
  description: string,
  value: T
}

export interface OptionsData {
  showWindow: boolean,
  m_dbClick: OptionsValue<number>,
  m_longClick: OptionsValue<number>,
  m_deckSnap: OptionsValue<number>,
  m_rotationAmount: OptionsValue<number>,
  k_shuffle: OptionsValue<string>,
  k_group: OptionsValue<string>,
  k_sortAsc: OptionsValue<string>,
  k_sortDesc: OptionsValue<string>,
  k_zoom: OptionsValue<string>,
  k_showDeck: OptionsValue<string>,
  k_flip: OptionsValue<string>,
  k_rotate: OptionsValue<string>,
  k_backgroundColor: OptionsValue<string>,
  t_keepZoomOpen: OptionsValue<boolean>,
  t_uniformScale: OptionsValue<boolean>,
  t_rotateOnWheel: OptionsValue<boolean>,
  t_useShadows: OptionsValue<boolean>
}

export const defaultOptions: OptionsData = {
  showWindow: false,
  m_dbClick: { description: 'Double click', value: 300 },
  m_longClick: { description: 'Long click', value: 500 },
  m_deckSnap: { description: 'Deck Snap', value: 20 },
  m_rotationAmount: { description: 'Wheel rotation', value: 10 },
  k_shuffle: { description: 'Shuffle', value: 's' },
  k_group: { description: 'Group', value: 'g' },
  k_sortAsc: { description: 'Sort Asc', value: 'a' },
  k_sortDesc: { description: 'Sort Desc', value: 'd' },
  k_zoom: { description: 'Zoom', value: 'z' },
  k_showDeck: { description: 'Show Deck', value: 'o' },
  k_flip: { description: 'Flip', value: 'f' },
  k_rotate: { description: 'Rotate', value: 'r' },
  k_backgroundColor: { description: 'Background Color', value: 'rgb(58 61 74)' },
  t_keepZoomOpen: { description: 'Keep Zoom Window Open', value: false },
  t_uniformScale: { description: 'Uniform Scale (Zoom)', value: true },
  t_rotateOnWheel: { description: 'Rotate on wheel', value: true },
  t_useShadows: { description: 'Use Shadows', value: true }
}

export default OptionsData
