import { useCallback, useMemo, useRef } from "react";
import { CardData } from "common";
import GameCard from "../GameCard";
import MovableCollection, { DefaultState, ItemType } from "../MovableCollections";
import "./style.css"

export interface DeckViewProps {
  cards: CardData[],
  onClose: () => void,
  onSave: (deckId: number, order: number[]) => void
}

interface DeckViewState extends DefaultState {
  order: { [key: number]: number }
}

type CardSelector = { [key: number]: JSX.Element }

const cardsInRow = 10
const padding = 20
const [ leftConst, topConst ] = [ 10, 80 ]
const minWidth = 135

function orderCards(width: number, height: number, cards: ItemType[], skipId?: number) {
  function getXY(idx: number): { x: number, y: number } {
    const row = ~~(idx / cardsInRow) // whole division
    const col = idx % cardsInRow
    return {
      x: col * (width + padding) + leftConst,
      y: row * (height + padding) + topConst
    }
  }

  cards.forEach((card, index) => {
    if (card.id === skipId) {
      return
    }
    const { x, y } = getXY(index)
    card.state.x = x
    card.state.y = y
    card.state.z = 1
  })
}

function createInitialState(cards: CardData[]): DeckViewState {
  const items: ItemType[] = cards.map(card => ({ id: card.id, state: { ...card.state, z: 1 } })) // deep copy
  orderCards(cards[0].dimensions.width, cards[0].dimensions.height, items)

  const initial: { [key: number]: number } = {}
  const order = items.reduce((acc, card, idx) => {
    acc[card.id] = idx
    return acc
  }, initial)

  return { items, order }
}

function sortInPlace(items: ItemType[], index: number, width: number, height: number) {
  //items[index].state = { ...items[index].state, y: items[index].state.y - 20 }
  const rows = ~~(items.length / cardsInRow)
  const rawRow = ~~((items[index].state.y - topConst + (height/2)) / (height + padding))
  const row = Math.max(0, Math.min(rawRow, rows))

  const rawCol = ~~((items[index].state.x - leftConst + (width/2)) / (width + padding))
  const col = Math.max(0, Math.min(rawCol, cardsInRow - 1))

  const tmp = { ...items[index] }
  items[index].id = -1

  let targetIdx = row * cardsInRow + col
  if (targetIdx > index) {
    targetIdx += 1 // original element will be removed, thus will be shifted one to the left
  }

  items.splice(targetIdx, 0, tmp)
  const newIdx = items.findIndex(card => card.id === -1)! // idx could be changed due to the insertion above
  items.splice(newIdx, 1)
}

export default function DeckView(props: DeckViewProps) {
  const cardHeight = props.cards[0].dimensions.height + padding
  const cardWidth = props.cards[0].dimensions.width + padding
  const rows = Math.ceil(props.cards.length / cardsInRow)
  const height = (rows + 0) * cardHeight + topConst - (padding/2)
  const width = Math.max(minWidth, cardWidth * Math.min(cardsInRow, props.cards.length) - (padding/2))

  const cards = useMemo(() => props.cards.reduce<CardSelector>((acc, card, idx) => {
    acc[card.id] = (
      <GameCard
        dimensions={card.dimensions}
        layout={card.layout}
        flipped={card.state.flipped}
        selected={false}
        focused={false}
        rotation={undefined}
        useShadows={ true }
        text={card.layout.frontText}
        textStyle={card.layout.frontTextLayout}
      >
        <span className='deckViewOrder'>{ idx }</span>
      </GameCard>
    )
    return acc
  }, {}), [props.cards])

  const cardSelector = useCallback((_: DeckViewState, id: number) => cards[id], [cards])
  const targetSelector = useCallback((target: HTMLElement) => target.parentElement!.parentElement, [])

  const onMove = useCallback((state: DeckViewState, id: number) => {
    const { width, height } = props.cards[0].dimensions
    const index = state.items.findIndex(card => card.id === id)
    sortInPlace(state.items, index, width, height)
    orderCards(width, height, state.items, id)
  }, [props.cards])

  const onStop = useCallback((state: DeckViewState, _: number) => {
    const { width, height } = props.cards[0].dimensions
    orderCards(width, height, state.items)
    ref.current = state.items.map(card => card.id)
  }, [props.cards])

  const initialSate = useMemo(() => createInitialState(props.cards), [props.cards])
  const ref = useRef(initialSate.items.map(card => card.id))

  return (
    <div className="deckViewContainer" style={{ width, height }}>
      <span style={{ fontSize: 30 }}>Deck View</span>
      <div>
        <button onClick={ props.onClose }>Cancel</button>
        <button onClick={ () => {
          props.onSave(props.cards[0].state.deckId!, ref.current)
          props.onClose()
         } }>Save</button>
      </div>
      
      {
        <MovableCollection
          state={ initialSate }
          selector={ cardSelector }
          onMove={ onMove }
          onStop={ onStop }
          targetSelector={ targetSelector }
        />
      }
    </div>
  )

}
