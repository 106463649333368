import { fromEvent, merge, Observable, Subject } from "rxjs";
import { filter, map, share, startWith, switchMap, take, takeUntil } from "rxjs/operators";
import { PlayAreaEvent } from "../api/PlayAreaEvent";
import { MouseStreams } from "../api/MouseStream";
import { StreamEvent } from "../api/StreamEvent";
import { toCommonEvent } from "./utilities";
import { ItemState } from "common";

const _mouseUp$ = merge(
  fromEvent<MouseEvent>(document, 'mouseup').pipe(map(toCommonEvent)),
  fromEvent<TouchEvent>(document, 'touchend').pipe(map(toCommonEvent))
).pipe(share({ connector: () => new Subject() }));

const _mouseMove$ = merge(
  fromEvent<MouseEvent>(document, 'mousemove').pipe(map(toCommonEvent)),
  fromEvent<TouchEvent>(document, 'touchmove').pipe(map(toCommonEvent))
).pipe(share({ connector: () => new Subject() }));

export function createMouseStream<T extends { state: ItemState }>(events$: Observable<PlayAreaEvent>, longClickTime: () => number, selector: (id: number) => T): MouseStreams<T> {

  const mouseDown$: Observable<StreamEvent<T>> = events$.pipe(
    map(event => {
      const { id, pageX, pageY } = event
      const element = selector(id)
      return {
        id,
        element,
        pageX,
        pageY,
        offsetX: pageX - element.state.x,
        offsetY: pageY - element.state.y,
        longPressed: false,
        rightClick: event.rightClick
      }
    }),
    share({ connector: () => new Subject() })
  )

  return {
    mouseDown$,
    mouseMove$: mouseDown$.pipe(
      filter(_ => !_.rightClick),
      switchMap(streamEvent => {
        const startTime = Date.now()
        return _mouseMove$.pipe(
          take(1),
          takeUntil(_mouseUp$),
          switchMap(firstCommonEvent => {
            const longPressed = Date.now() - startTime > longClickTime()
            return _mouseMove$.pipe(
              map(commonEvent => ({
                ...streamEvent,
                ...commonEvent,
                longPressed,
                element: selector(streamEvent.id)
              })),
              startWith({
                ...streamEvent,
                ...firstCommonEvent,
                longPressed,
                element: selector(streamEvent.id)
              }),
              takeUntil(_mouseUp$)
            )
          })
        )
      }),
      share({ connector: () => new Subject() })
    ),
    mouseUp$: mouseDown$.pipe(
      filter(_ => !_.rightClick),
      switchMap(streamEvent => _mouseUp$.pipe(
        map(commonEvent => ({
          ...streamEvent,
          ...commonEvent,
          element: selector(streamEvent.id)
        })),
        take(1)
      )),
      share({ connector: () => new Subject() })
    )
  }

}
