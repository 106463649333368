import LocalState, { defaultLocalState } from "../../api/LocalState"
import { LocalActionTypes } from "./localActionTypes"
import * as T from './localActionTypes'

export default function localReducer(state: LocalState = defaultLocalState, action: LocalActionTypes) {
  switch (action.type) {
    case T.TOGGLE_HAND:
      state.handId = action.id
      break
    case T.CREATE_SELECTION:
      state.selection.cards = new Set(action.cardSelection)
      state.selection.decks = new Set(action.deckSelection)
      state.selection.dice = new Set(action.dieSelection)
      break
    case T.SHOW_DECK:
      state.showDeck = action.deckId
      break
    case T.ZOOM_ON_CARD:
      state.zoonOnCard = action.cardId
      break
    default:
      return state
  }
}
