import { useCallback, useMemo } from "react";
import { DieData } from "common";
import globalSubjects from "../../streams/globalSubjects";
import Movable from "../Movable";
import { useOptions } from "../Options/atom";

interface DieProps {
  die: DieData,
  selected: boolean
}

export default function Die(props: DieProps) {
  const useShadows = useOptions().current.t_useShadows.value
  const { die: { dimensions }, selected } = props
  const side = props.die.sides[props.die.current]

  const cardUIStyle: React.CSSProperties = useMemo(() => {
    const shadowColor = selected ? 'rgba(0, 255, 0, 0.4)' : 'rgba(0, 0, 0, 0.4)'
    const filterBase = `drop-shadow(1px 1px 2px ${shadowColor})`
    const filter = !selected ? filterBase : `drop-shadow(0px 0px 2px green) ${filterBase}`
    return {
      ...dimensions,
      backgroundImage: `url(${ side })`,
      backgroundSize: 'contain',
      borderRadius: 3,
      outline: !useShadows && selected ? 'solid 2px green' : undefined,
      filter: useShadows ? filter : undefined
    }
  }, [selected, side, dimensions])

  const targetSelector = useCallback((_: HTMLElement) => _.parentElement, [])

  return (
    <Movable id={ props.die.id } state={ props.die.state } mouse$={ globalSubjects.playAreaDie$ } targetSelector={ targetSelector }>
      <div style={ cardUIStyle }/>
    </Movable>
  )
}
