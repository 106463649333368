import { MutableRefObject, useMemo } from "react"
import { useRecoilValue } from "recoil"
import { Subject } from "rxjs"
import userNameAtom from "../../api/userName"
import { shuffleArray } from "../../streams/utilities"
import groupIntoSubLists from "../../utilities/utilities"
import { createSelection, showDeck, zoomOnCard } from "./localActionCreators"
import { LocalActionTypes } from "./localActionTypes"
import { PlayAreaState, GameConfig, MOVE_KIND, PlayAreaActionTypes, syncState } from "common"
import { addHand, cardHandOrder, cardOwner, createDeck, deleteHand, handColor, lockCard, moveTo, removeFromDeck, rotateCard, selectGame, shuffleDeck, shuffleHand, syncStateReq, toggleHandOwner, toggleTurnMarker } from "common"

export interface LocalActionHandlers {
  onSelected: (cards: number[], decks: number[], dice: number[]) => void,
  onGameChange: (_: GameConfig) => void,
  onShuffleDeck: (deckId: number, order?: number[]) => void,
  onShuffleHand: (_: number) => void
  onDeal: (_: number) => void,
  onGrid: (deckId: number, cols: number, rows: number, count: number) => void,
  onZoom: (_: number) => void,
  onGroup: (_: number[]) => void,
  onRotate: (id: number, deg: number) => void,
  onShowDeck: (_: number) => void,
  onSort: (deckId: number, asc: boolean) => void,
  onCardSelectionShuffle: (_: number[]) => void,
  onHideDeck: () => void,
  onHideZoomedCard: () => void,
  onSync: () => void,
  sendSync: (state: PlayAreaState) => void,
  onAddHand: () => void,
  onLock: (_: number) => void,
  unSeat: (_: number) => void,
  onDeleteHand: (_: number) => void,
  onHandColor: (id: number, color: string) => void,
  onToggleTurnMarker: () => void
}

function createLocalActionHandlers(
  username: string,
  commonDispatch: Subject<PlayAreaActionTypes>,
  localDispatch: (_: LocalActionTypes) => void,
  commonStateRef: MutableRefObject<PlayAreaState>
): LocalActionHandlers {

  return {
    onSelected: (cards: number[], decks: number[], dice: number[]) => localDispatch(createSelection(cards, decks, dice)),
    onGameChange: (config: GameConfig) => {
      localDispatch(createSelection([], [], []))
      localDispatch(zoomOnCard(undefined))
      commonDispatch.next(selectGame(config))
    },
    onShuffleDeck: (deckId: number, order?: number[]) => commonDispatch.next(shuffleDeck(order ?? shuffleArray([ ...commonStateRef.current.decks[deckId].cards ]))),
    onShuffleHand: (handId: number) => commonDispatch.next(shuffleHand(shuffleArray([ ...commonStateRef.current.hands[handId].state.cards ]))),
    onDeal: (deckId: number) => {
      const cardsInDeck = [ ...commonStateRef.current.decks[deckId].cards ]
      const targets = Object.values(commonStateRef.current.hands).filter(hand => hand.state.owners.length > 0)
      const n = Math.min(targets.length, cardsInDeck.length)
      for (let i=0; i<n; i++) {
        // if there is only one card left in the deck,
        // the deck gets disassambled automatically
        if (i < cardsInDeck.length - 1) {
          commonDispatch.next(removeFromDeck(cardsInDeck[i]))
        }
        commonDispatch.next(cardOwner(cardsInDeck[i], targets[i].id))
        commonDispatch.next(cardHandOrder(cardsInDeck[i]))
      }
    },
    onGrid: (deckId: number, cols: number, rows: number, count: number) => {
      if (commonStateRef.current.decks[deckId].cards.length < cols * rows * count) {
        alert('Too few card in deck for selected grid laout.')
        return
      }
  
      const cardsInDeck = groupIntoSubLists([ ...commonStateRef.current.decks[deckId].cards ], count)
      const { state: { x, y }, dimensions: { width, height } } = commonStateRef.current.cards[cardsInDeck[0][0]]
      for (var i=0; i<rows; i++) {
        for (var j=0; j<cols; j++) {
          const groupIdx = i*cols + j
          const group = cardsInDeck[groupIdx]
          for (var c=0; c<count; c++) {
            const cardId = group[c]
            if (groupIdx < cardsInDeck.length - 1 || c < count - 1) {
              commonDispatch.next(removeFromDeck(cardId))
            }
            commonDispatch.next(moveTo(MOVE_KIND.CARD, cardId, x + ((j+1) * (width + 10)), y + (i * (height + 10))))
          }
          if (count > 1) {
            commonDispatch.next(createDeck(...group))
          }
        }
      }
    },
    onGroup: (cards: number[]) => {
      const selectedCards = cards.map(id => commonStateRef.current.cards[id])
      const groups = selectedCards
        .filter(card => card.state.deckId === undefined && card.state.handId === undefined)
        .reduce<{[key: number]: number[]}>((acc, curr) => {
          acc[curr.type] = (acc[curr.type] ?? [])
          acc[curr.type].push(curr.id)
          return acc
        }, {})
  
      localDispatch(createSelection([], [], []))
      Object.values(groups)
        .filter(group => group.length > 1)
        .forEach(group => commonDispatch.next(createDeck(...group)))
    },
    onZoom: (cardId: number) => localDispatch(zoomOnCard(cardId)),
    onRotate: (cardId: number, deg: number) => {
      const currentRotation = commonStateRef.current.cards[cardId].state.rotation ?? 0
      const newRotation = (currentRotation + deg) % 360
      commonDispatch.next(rotateCard(cardId, newRotation))
    },
    onSort: (deckId: number, asc: boolean) => {
      const cardsInDeck = commonStateRef.current.decks[deckId].cards.map(_ => commonStateRef.current.cards[_])
      cardsInDeck.sort((a, b) => asc ? a.value - b.value : b.value - a.value)
      commonDispatch.next(shuffleDeck(cardsInDeck.map(_ => _.id)))
    },
    onCardSelectionShuffle: (cards: number[]) => {
      const selectedCards = cards.map(id => commonStateRef.current.cards[id])
      const positions = selectedCards.map(_ => ({ x: _.state.x, y: _.state.y}))
      shuffleArray(positions)
      selectedCards.forEach((card, idx) => {
        const { x, y } = positions[idx]
        commonDispatch.next(moveTo(MOVE_KIND.CARD, card.id, x, y))
      })
    },
    onShowDeck: (deckId: number) => localDispatch(showDeck(deckId)),
    onHideDeck: () => localDispatch(showDeck(undefined)),
    onHideZoomedCard: () => localDispatch(zoomOnCard(undefined)),
    onSync: () => commonDispatch.next(syncStateReq()),
    sendSync: state => commonDispatch.next(syncState(state)),
    onAddHand: () => {
      let id = 0
      do {
        id = 1000 + Math.floor(Math.random() * 1000)
      } while (commonStateRef.current.hands[id] !== undefined)
      commonDispatch.next(addHand(id, username, 100, 100))
    },
    onLock: (cardId: number) => {
      const newState = !commonStateRef.current.cards[cardId].constraints.movable
      commonDispatch.next(lockCard(cardId, newState))
    },
    unSeat: (id: number) => {
      commonStateRef.current.hands[id].state.owners.forEach(owner => {
        commonDispatch.next(toggleHandOwner(id, owner))
      })
    },
    onDeleteHand: (id: number) => commonDispatch.next(deleteHand(id)),
    onHandColor: (id: number, color: string) => commonDispatch.next(handColor(id, color)),
    onToggleTurnMarker: () => {
      const toggle = commonStateRef.current.cards[0] !== undefined
      commonDispatch.next(toggleTurnMarker(!toggle))
    }
  }
}

export function useActionHandlers(
  commonDispatch: Subject<PlayAreaActionTypes>,
  localDispatch: (_: LocalActionTypes) => void,
  commonStateRef: MutableRefObject<PlayAreaState>,
): LocalActionHandlers {
  const username = useRecoilValue(userNameAtom)
  return useMemo(() => createLocalActionHandlers(username, commonDispatch, localDispatch, commonStateRef), [username, commonDispatch, localDispatch, commonStateRef])
}
