"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultPlayAreaState = void 0;
function createDefaultPlayAreaState() {
    return {
        cards: {},
        dice: [],
        pouches: [],
        decks: {},
        zIndex: 0,
        deckId: 0,
        hands: {}
    };
}
exports.createDefaultPlayAreaState = createDefaultPlayAreaState;
