import { MutableRefObject, useMemo } from "react";
import { PlayAreaState } from "common";
import { useOptions } from "../Components/Options/atom";
import globalSubjects from "./globalSubjects";
import { createMouseStream } from "./mouseStreams";

export default function usePlayAreaStreams(stateRef: MutableRefObject<PlayAreaState>) {
  const optionsRef = useOptions()
  return useMemo(() => {
    const longClickTime = () => optionsRef.current.m_longClick.value
    const card$ = createMouseStream(globalSubjects.playAreaMouseCard$, longClickTime, id => stateRef.current.cards[id])
    const hand$ = createMouseStream(globalSubjects.playAreaHand$, longClickTime, id => stateRef.current.hands[id])
    const die$ = createMouseStream(globalSubjects.playAreaDie$, longClickTime, id => stateRef.current.dice[id])
    const pouch$ = createMouseStream(globalSubjects.playAreaPouch$, longClickTime, id => stateRef.current.pouches[id])

    return { card$, hand$, die$, pouch$ }
  }, [stateRef, optionsRef])
}
