import { MouseEventHandler, ReactNode, useMemo } from "react"
import { ItemDimensions, ItemLayout } from "common"

export interface StaticCardProps {
  dimensions: ItemDimensions,
  layout: ItemLayout,
  rotation?: number,
  focused: boolean,
  flipped: boolean,
  selected: boolean,
  children?: ReactNode,
  useShadows: boolean,
  amendStyle?: React.CSSProperties,

  // DOMAttributes<HTMLDivElement>
  onMouseEnter?: MouseEventHandler<HTMLDivElement>,
  onMouseLeave?: MouseEventHandler<HTMLDivElement>
}

export default function StaticCard(props: StaticCardProps) {
  const { flipped, selected, amendStyle, dimensions, layout, rotation, focused, useShadows } = props

  const cardUIStyle: React.CSSProperties = useMemo(() => {
    const shadowColor = selected ? 'rgba(255, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.4)'
    const shadowOffset = focused ? 5 : 1
    const filterBase = `drop-shadow(${shadowOffset}px ${shadowOffset}px 2px ${shadowColor})`
    const filter = !selected ? filterBase : `drop-shadow(0px 0px 2px red) ${filterBase}`
    return {
      ...dimensions,
      backgroundColor: flipped ? layout.backColor : layout.frontColor,
      backgroundImage: `url(${ flipped ? layout.backImage : layout.frontImage })`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      transform: rotation ? `rotate(${rotation}deg)` : undefined,
      borderRadius: 3,
      outline: !useShadows && props.selected ? 'solid 2px red' : undefined,
      filter: useShadows ? filter : undefined,
      ...amendStyle
    }
  }, [flipped, selected, focused, amendStyle, dimensions, layout, rotation, useShadows])

  return (
    <div style={ cardUIStyle } onMouseEnter={ props.onMouseEnter } onMouseLeave={ props.onMouseLeave } >
      { props.children }
    </div>
  )
}
