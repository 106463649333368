import { Subject, Subscription } from "rxjs";
import io from "socket.io-client"
import { PlayerActionTypes } from "../Components/Actions/playerActionTypes";
import { PlayAreaActionTypes } from "common";

export default function createWebSocket(roomCode: string): [Subject<PlayAreaActionTypes[]>, Subject<PlayAreaActionTypes[]>, Subject<PlayerActionTypes>] {
  const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_WS_PROD_URL! : process.env.REACT_APP_WS_DEV_URL!
  const in$ = new Subject<PlayAreaActionTypes[]>()
  const out$ = new Subject<PlayAreaActionTypes[]>()
  const event$ = new Subject<PlayerActionTypes>()
  let sub: Subscription

  const socket = io(url, { query: { roomCode } })
  socket
    .on('connect', () => {
      sub = out$.subscribe(_ => socket.emit('play', _))
    })
    .on('disconnect', () => sub.unsubscribe())
    .on('play', (_: any) => in$.next(_))
    .on('event', (_: any) => event$.next(_))
  
  return [ in$, out$, event$ ]
}
