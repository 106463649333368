import { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import userNameAtom from "../../api/userName";
import { shuffleArray } from "../../streams/utilities";

const PLAYAREA_URL = "/playarea?c=$code";

function generateRoomCode() {
  const abc = shuffleArray("ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split(''))
  return Array.from({ length: 5 }, _ => abc[Math.floor(Math.random() * abc.length)]).join('')
}

export default function Home() {
  const navigate = useNavigate()
  const setUserName = useSetRecoilState(userNameAtom)

  const usernameRef = useRef<HTMLInputElement>(null)
  const invitationRoomCode = (useLocation().search.match('c=(.*)') ?? ['', undefined])[1]

  const onSubmit = () => {
    const username = (usernameRef.current?.value ?? "").trim()
    if (username.length < 1) {
      alert("Invalid name.")
      return
    }

    const roomCode = invitationRoomCode ?? generateRoomCode()
    setUserName(username)
    navigate(PLAYAREA_URL.replace("$code", roomCode))
  }

  return (
    <div style={{ color: 'white' }}>
      <form onSubmit={ onSubmit }>
        <span>Name:</span>
        <input ref={ usernameRef } type="text" />
        <button type="submit">{ invitationRoomCode ? 'JOIN' : 'CREATE' }</button>
      </form>
    </div>
  )
}
