import { CardData } from 'common';
import StaticCard from '../StaticCard';
import Movable from '../Movable';
import globalSubjects from '../../streams/globalSubjects';
import { useCallback } from 'react';
import { useOptions } from '../Options/atom';

interface PouchProps {
  template: CardData
}

export default function Pouch(props: PouchProps) {
  const options = useOptions()
  const targetSelector = useCallback((_: HTMLElement) => _.parentElement, [])
  return (
    <Movable id={ props.template.id } state={ props.template.state } mouse$={ globalSubjects.playAreaPouch$ } targetSelector= { targetSelector } >
      <StaticCard
        dimensions={ props.template.dimensions }
        layout={ props.template.layout }
        rotation={ undefined }
        useShadows={ options.current.t_useShadows.value }
        focused={ props.template.state.focused }
        flipped={ false }
        selected={ false }
      />
      <div style={{ position: 'absolute', left: 0, top: -25, zIndex: 0, display: 'inline-flex', userSelect: 'none' }}>
        <div style={{paddingLeft:'5px', paddingRight:'5px', backgroundColor:'lightgray'}}>&lt;inf&gt;</div>
      </div>
    </Movable>
  )
}
