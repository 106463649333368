"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTurnMarker = exports.loadGame = void 0;
function loadGame(config) {
    var _a, _b;
    var cardId = 1;
    var gameInstance = config.decks.reduce(function (gameInstance, deck, deckIndex) {
        var yOffset = deckIndex * 100 + 30;
        var generatedCards = deck.cards.flatMap(function (cardConfig) {
            return Array.from({ length: cardConfig.count }, function (_) {
                var _a, _b, _c, _d, _e, _f, _g;
                var cardData = {
                    id: cardId,
                    type: cardConfig.type,
                    state: {
                        x: 10,
                        y: yOffset,
                        z: cardId,
                        flipped: false,
                        visible: false,
                        focused: false,
                        handId: undefined,
                        deckId: deckIndex
                    },
                    value: (_a = cardConfig.value) !== null && _a !== void 0 ? _a : 0,
                    constraints: {
                        movable: true,
                        turnable: (_c = (_b = cardConfig.constraints) === null || _b === void 0 ? void 0 : _b.turnable) !== null && _c !== void 0 ? _c : true,
                        ownable: (_e = (_d = cardConfig.constraints) === null || _d === void 0 ? void 0 : _d.ownable) !== null && _e !== void 0 ? _e : true,
                        topable: (_g = (_f = cardConfig.constraints) === null || _f === void 0 ? void 0 : _f.topable) !== null && _g !== void 0 ? _g : true
                    },
                    layout: {
                        frontImage: cardConfig.layout.front.image,
                        frontText: cardConfig.layout.front.text,
                        frontTextLayout: cardConfig.layout.front.textLayout,
                        backImage: cardConfig.layout.back.image,
                        frontColor: '',
                        backColor: ''
                    },
                    dimensions: cardConfig.dimensions
                };
                cardId = cardId + 1;
                return cardData;
            });
        });
        if (generatedCards.length > 0) {
            generatedCards[0].state.visible = true;
            generatedCards.forEach(function (generatedCard) { return gameInstance.cards[generatedCard.id] = generatedCard; });
        }
        gameInstance.decks[deckIndex] = {
            id: deckIndex,
            cards: generatedCards.map(function (card) { return card.id; })
        };
        return gameInstance;
    }, {
        title: config.title,
        cards: {},
        dice: [],
        pouches: [],
        decks: {}
    });
    var dieId = 0;
    (_a = config.dice) === null || _a === void 0 ? void 0 : _a.forEach(function (configDie, idx) {
        for (var i = 0; i < configDie.count; i++) {
            gameInstance.dice.push({
                id: dieId,
                type: 0,
                state: {
                    x: 100,
                    y: dieId * 50 + 50,
                    z: 1,
                    flipped: false,
                    visible: true,
                    focused: false
                },
                constraints: {
                    movable: true,
                    turnable: false,
                    ownable: false,
                    topable: true
                },
                layout: {},
                dimensions: {
                    width: configDie.size,
                    height: configDie.size
                },
                sides: configDie.sides,
                current: 0
            });
            dieId += 1;
        }
    });
    var pouchId = 0;
    (_b = config.pouches) === null || _b === void 0 ? void 0 : _b.forEach(function (pouch) {
        var _a, _b, _c, _d, _e, _f;
        gameInstance.pouches.push({
            id: pouchId,
            type: pouch.type,
            state: {
                x: 160,
                y: pouchId * 60 + 50,
                z: cardId,
                flipped: false,
                visible: true,
                focused: false
            },
            value: 0,
            constraints: {
                movable: true,
                turnable: (_b = (_a = pouch.constraints) === null || _a === void 0 ? void 0 : _a.turnable) !== null && _b !== void 0 ? _b : true,
                ownable: (_d = (_c = pouch.constraints) === null || _c === void 0 ? void 0 : _c.ownable) !== null && _d !== void 0 ? _d : true,
                topable: (_f = (_e = pouch.constraints) === null || _e === void 0 ? void 0 : _e.topable) !== null && _f !== void 0 ? _f : true
            },
            layout: {
                frontImage: pouch.layout.front.image,
                frontText: pouch.layout.front.text,
                frontTextLayout: pouch.layout.front.textLayout,
                backImage: pouch.layout.back.image,
                frontColor: '',
                backColor: ''
            },
            dimensions: pouch.dimensions
        });
        pouchId += 1;
    });
    return gameInstance;
}
exports.loadGame = loadGame;
function createTurnMarker() {
    return {
        id: 0,
        type: -1,
        state: {
            x: 300,
            y: 30,
            z: 1,
            flipped: false,
            visible: true,
            focused: false,
            handId: undefined,
            deckId: undefined
        },
        value: 0,
        constraints: {
            movable: true,
            turnable: false,
            ownable: true,
            topable: true
        },
        layout: {
            frontImage: '',
            backImage: '',
            frontColor: 'red',
            backColor: ''
        },
        dimensions: {
            width: 50,
            height: 70
        }
    };
}
exports.createTurnMarker = createTurnMarker;
