export const TOGGLE_HAND = 'TOGGLE_HAND'
export const CREATE_SELECTION = 'CREATE_SELETION'
export const SHOW_DECK = 'SHOW_DECK'
export const ZOOM_ON_CARD = 'ZOOM_ON_CARD'
export const NOOP = 'NOOP'

export interface CreateSelectionAction {
  type: typeof CREATE_SELECTION,
  cardSelection: number[],
  deckSelection: number[],
  dieSelection: number[]
}

export interface ToggleHandAction {
  type: typeof TOGGLE_HAND,
  id?: number
}

export interface ShowDeckAction {
  type: typeof SHOW_DECK,
  deckId?: number
}

export interface ZoomOnCardAction {
  type: typeof ZOOM_ON_CARD,
  cardId?: number
}

export interface NoopAction {
  type: typeof NOOP
}

export type LocalActionTypes = NoopAction | CreateSelectionAction | ToggleHandAction | ShowDeckAction | ZoomOnCardAction
