import { MutableRefObject, useMemo } from "react"
import { useSetRecoilState } from "recoil"
import { filter, fromEvent, map, merge, Observable, share, Subject, tap } from "rxjs"
import ContextMenuData from "../api/ContextMenuData"
import LocalState from "../api/LocalState"
import OptionsData from "../api/OptionsData"
import { PlayAreaState } from "common"
import { PlayAreaStreams } from "../api/PlayAreaStreams"
import contextMenuAtom from "../Components/ContextMenu/atom"
import { cardContextGroup, cardSelectionContextGroup, deckContextGroup, handContextGroup } from "../Components/ContextMenu/groups"
import { useOptions } from "../Components/Options/atom"
import { LocalActionHandlers } from "../Pages/PlayArea/actionHandlers"
import { createSelection, noop, showDeck, zoomOnCard } from "../Pages/PlayArea/localActionCreators"
import { LocalActionTypes } from "../Pages/PlayArea/localActionTypes"
import globalSubjects from "./globalSubjects"
import { getTripleClickStream } from "./utilities"

function createLocalStreams(
  mouseStreams: PlayAreaStreams,
  stateRef: MutableRefObject<PlayAreaState>,
  localStateRef: MutableRefObject<LocalState>,
  optionsRef: MutableRefObject<OptionsData>,
  actions: LocalActionHandlers,
  setContextMenu: (_: ContextMenuData | undefined) => void
): Observable<LocalActionTypes> {
  const _mouseDown$ = merge(
    fromEvent<MouseEvent>(document, 'mousedown').pipe(
      filter(_ => _.button !== 2),
    )
  ).pipe(share({ connector: () => new Subject() }))

  const keyDown$ = globalSubjects.playAreaKeyboardCard$
  const keyUp$ = fromEvent(document, 'keyup').pipe(
    map(_ => _ as KeyboardEvent),
    share({ connector: () => new Subject() })
  )

  const zoomOnCard$ = keyDown$.pipe(
    filter(_ => _.event.key.toLowerCase() === optionsRef.current.k_zoom.value && localStateRef.current.zoonOnCard !== _.id ),
    map(_ => zoomOnCard(_.id))
  )

  // TODO: only end selection if the keyup happened over selected card
  const endSelection$ = keyDown$.pipe(
    filter(_ => _.event.key.toLowerCase() === optionsRef.current.k_group.value),
    map(_ => createSelection([], [], []))
  )

  const openDeck$ = keyDown$.pipe(
    filter(_ => _.event.key.toLowerCase() === optionsRef.current.k_showDeck.value),
    filter(_ => localStateRef.current.showDeck === undefined),
    map(_ => stateRef.current.cards[_.id].state.deckId),
    filter(_ => _ !== undefined),
    map(_ => showDeck(_))
  )

  const showContextMenu$ = merge(
    getTripleClickStream(mouseStreams.card$.mouseDown$, () => optionsRef.current.m_dbClick.value, (a, b) => a.id === b.id),
    mouseStreams.card$.mouseDown$.pipe(filter(_ => _.rightClick))
  ).pipe(
    tap(_ => {
      const { id, state: { deckId, x, y }, dimensions: { width } } = stateRef.current.cards[_.id]
      if (deckId !== undefined) {
        setContextMenu({
          x: x + width + 10,
          y,
          group: deckContextGroup(deckId, actions)
        })
      } else {
        if (localStateRef.current.selection.cards.has(id)) {
          const ids = Array.from(localStateRef.current.selection.cards)
          setContextMenu({
            x: x + width + 10,
            y,
            group: cardSelectionContextGroup(ids, actions)
          })
        } else {
          setContextMenu({
            x: x + width + 10,
            y,
            group: cardContextGroup(id, actions)
          })
        }
      }
    }),
    map(_ => noop()
  ))

  const showHandContextMenu$ = merge(
    getTripleClickStream(mouseStreams.hand$.mouseDown$, () => optionsRef.current.m_dbClick.value, (a, b) => a.id === b.id),
    mouseStreams.hand$.mouseDown$.pipe(filter(_ => _.rightClick))
  ).pipe(
    tap(_ => {
      const { id, state: { x, y }, dimensions: { width } } = stateRef.current.hands[_.id]
      setContextMenu({
        x: x + width + 10,
        y,
        group: handContextGroup(id, actions)
      })
    }),
    map(_ => noop()
  ))

  const hideContextMenu$ = _mouseDown$.pipe(
    tap(_ => setContextMenu(undefined)),
    map(_ => noop())
  )

  const closeZoom$ = keyUp$.pipe(
    filter(_ => _.key.toLowerCase() === optionsRef.current.k_zoom.value && !optionsRef.current.t_keepZoomOpen.value),
    map(_ => zoomOnCard(undefined))
  )

  return merge(zoomOnCard$, closeZoom$, endSelection$, showContextMenu$, showHandContextMenu$, hideContextMenu$, openDeck$)
}

export default function useLocalStreams(mouseStreams: PlayAreaStreams, stateRef: MutableRefObject<PlayAreaState>, localStateRef: MutableRefObject<LocalState>, actions: LocalActionHandlers): Observable<LocalActionTypes> {
  const setContextMenu = useSetRecoilState(contextMenuAtom)
  const optionsRef = useOptions()

  return useMemo(() => {
    return createLocalStreams(mouseStreams, stateRef, localStateRef, optionsRef, actions, setContextMenu)
  }, [mouseStreams, stateRef, localStateRef, optionsRef, actions, setContextMenu])
}
