import { Observable, Subject } from "rxjs";
import { buffer, debounceTime, filter, map } from "rxjs/operators";
import { CardData, HandData, PlayAreaState } from "common";
import { CommonEvent } from "../api/CommonEvent";
import { PlayAreaEvent, KeyboardPlayAreaEvent } from "../api/PlayAreaEvent";
import { StreamEvent } from "../api/StreamEvent";
import { Tuple } from "../api/Tuple";

export function shuffleArray(array: any[]) {
  const rand = new Uint16Array(array.length)
  window.crypto.getRandomValues(rand)
  for (let i = array.length - 1; i > 0; i--) {
    const j = rand[i] % (i + 1);
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

export function getDeckIdsForCardIds(state: PlayAreaState, ids: number[]) {
  return ids.map(id => state.cards[id].state.deckId!) // todo: filter nulls
}

export function tuple<T, K>(fst: T, snd: K): Tuple<T, K> {
  return { fst, snd }
}

export function getClosestCard(targetCard: CardData, cards: { [key: number]: CardData }, threshold: number, extraFilter: (c: CardData) => boolean = (_) => true): CardData | undefined {

  function distance(card: CardData): number {
    const { x, y } = card.state
    return Math.abs(targetCard.state.x - x) + Math.abs(targetCard.state.y - y)
  }

  const filteredCards = Object.values(cards)
    .filter(card => card.id !== targetCard.id)
    .filter(extraFilter)
  
  if (filteredCards.length === 0) {
    return undefined
  }

  const closestCard = filteredCards.reduce((prev, curr) => distance(prev) < distance(curr) ? prev: curr)
  return distance(closestCard) < threshold ? closestCard : undefined
}

export function getOverlappingHand(card: CardData, hands: { [key: number] : HandData }) {
  const [ cardLeft, cardRight, cardTop, cardBottom ] = [ card.state.x, card.state.x + card.dimensions.width, card.state.y, card.state.y + card.dimensions.height ]
  function isIntersectingHand(hand: HandData): boolean {
    const [ handLeft, handRight, handTop, handBottom ] = [ hand.state.x, hand.state.x + hand.dimensions.width, hand.state.y, hand.state.y + hand.dimensions.height ]
    return isOverlapping(handLeft, handTop, handRight, handBottom, cardLeft, cardTop, cardRight, cardBottom)
  }
  return Object.values(hands).find(isIntersectingHand)
}

export function isOverlapping(ax1: number, ay1: number, ax2: number, ay2: number, bx1: number, by1: number, bx2: number, by2: number): boolean {
  return !(bx1 > ax2 || bx2 < ax1 || by1 > ay2 || by2 < ay1)
}

export function getDbClickStream<T>(mouseDown$: Observable<T>, time: () => number, eq: (a: T, b: T) => boolean): Observable<T> {
  return mouseDown$.pipe(
    // todo: rethink this
    buffer(mouseDown$.pipe(debounceTime(time()))),
    filter(_ => _.length === 2 && eq(_[0], _[1])),
    map(_ => _[0])
  )
}

export function getTripleClickStream<T>(mouseDown$: Observable<T>, time: () => number, eq: (a: T, b: T) => boolean): Observable<T> {
  return mouseDown$.pipe(
    // todo: rethink this
    buffer(mouseDown$.pipe(debounceTime(time()))),
    filter(_ => _.length === 3 && eq(_[0], _[1]) && eq(_[0], _[2])),
    map(_ => _[0])
  )
}

function isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  const touches = (event as TouchEvent).touches
  return touches !== undefined && touches.length > 0
}

export function toCommonEvent(event: MouseEvent | TouchEvent): CommonEvent {
  const { pageX, pageY } = isTouchEvent(event) ? event.touches[0] : event
  return { pageX, pageY }
}

function isRightClick(event: MouseEvent | TouchEvent): boolean {
  return isTouchEvent(event) ? event.touches.length === 2 : event.button === 2
}

function onFocusHandler(event: MouseEvent) {
  (event.target as HTMLElement).focus()
}

function onBlurHandler(event: MouseEvent) {
  (event.target as HTMLElement).blur()
}

export function addMouseEventListener(
  id: number,
  node: HTMLElement,
  withFocus: boolean,
  subject$: Subject<PlayAreaEvent>,
  targetSelector?: (target: HTMLElement) => HTMLElement | null
): () => void {
  const eventHandler = (event: MouseEvent | TouchEvent) => {
    event.preventDefault()
    const target = event.target as HTMLElement
    if ((targetSelector?.(target) ?? target) !== node) {
      return
    }

    const { pageX, pageY } = toCommonEvent(event)
    const playAreaEvent = {
      id,
      pageX,
      pageY,
      offsetX: 0,
      offsetY: 0,
      rightClick: isRightClick(event)
    }
    subject$.next(playAreaEvent)
  }

  node.addEventListener('mousedown', eventHandler, { passive: false })
  node.addEventListener('touchstart', eventHandler, { passive: false })
  if (withFocus) {
    node.addEventListener('mouseenter', onFocusHandler)
    node.addEventListener('mouseleave', onBlurHandler)
  }
  return () => {
    node.removeEventListener('mousedown', eventHandler)
    node.removeEventListener('touchstart', eventHandler)
    if (withFocus) {
      node.removeEventListener('mouseenter', onFocusHandler)
      node.removeEventListener('mouseleave', onBlurHandler)
    }
  }
}

export function addKeyboardEventListener(id: number, node: HTMLElement, subject$: Subject<KeyboardPlayAreaEvent>): () => void {
  const eventHandler = (event: KeyboardEvent) => {
    const keyboardPlayAreaEvent = { id, event }
    subject$.next(keyboardPlayAreaEvent)
  }

  node.addEventListener('keydown', eventHandler, { passive: false })
  return () => {
    node.removeEventListener('keydown', eventHandler)
  }
}

export function getTopLeftCornerPos(event: StreamEvent<any>): { x: number, y: number } {
  const { pageX, pageY, offsetX, offsetY } = event
  return { x: pageX - offsetX, y: pageY - offsetY }
}
