import { MutableRefObject, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useSetRecoilState } from 'recoil';
import { GameConfig, PlayAreaState } from 'common';
import { loadConfig } from '../../games/helper';
import { LocalActionHandlers } from '../../Pages/PlayArea/actionHandlers';
import contextMenuAtom from '../ContextMenu/atom';
import GameSettings from '../GameSettings';
import optionsAtom from '../Options/atom';
import './index.css';
import SaveLoadGame from '../SaveLoadGame';

/*
const onCopyInvitationLink = () => {
  const invitationLink = window.location.href.replace("playarea", "")
  navigator.clipboard.writeText(invitationLink)
}
*/

interface MenuState {
  showGameSettings: boolean,
  showSaveLoad: boolean,
  lastGameURL: string,
  gameConfig?: GameConfig
}

interface MenuProps {
  actionHandlers: LocalActionHandlers,
  stateRef: MutableRefObject<PlayAreaState>
}

export default function Menu(props: MenuProps) {
  const setContextMenu = useSetRecoilState(contextMenuAtom)
  const setOptions = useSetRecoilState(optionsAtom)

  const [state, setState] = useState<MenuState>({
    showGameSettings: false,
    showSaveLoad: false,
    lastGameURL: 'http://tarsas.rarity.hu/games/PLACEHOLDER.yaml',
    gameConfig: undefined
  })

  const toggleMenu = () => {
    setContextMenu(current => {
      if (current !== undefined) {
        return undefined
      }
      return ({
        x: window.innerWidth - 145,
        y: 5,
        group: {
          title: 'Menu',
          items: [
            { text: 'Add Hand', close: true, action: () => props.actionHandlers.onAddHand() },
            { text: 'Toggle Turn Marker', close: true, action: () => props.actionHandlers.onToggleTurnMarker() },
            { text: 'Options', close: true, action: () => setOptions(_ => ({ ..._, showWindow: true })) },
            { text: 'Sync Request', close: true, action: () => props.actionHandlers.onSync() },
            { text: 'Game Settings', close: true, action: () => {
              if (state.gameConfig === undefined) {
                alert("No game loaded.")
                return
              }
              setState({ ...state, showGameSettings: true })
            }},
            { text: 'Load Game', close: true, action: () => {
              const url = prompt("URL", state.lastGameURL)
              if (url === null) {
                return      
              }

              loadConfig(url, gameConfig => {
                unstable_batchedUpdates(() => {
                  setState({ ...state, lastGameURL: url, gameConfig: gameConfig })
                })
                props.actionHandlers.onGameChange(gameConfig)
              })
            }},
            {
              text: 'Save/Load Game - JSON', close: true, action: () => {
                setState({ ...state, showSaveLoad: true })
              }
            }
          ]
        }
      })
    })
  }

  const onSaveGameSettings = (gameConfig: GameConfig) => {
    setState({ ...state, showGameSettings: false, gameConfig })
    props.actionHandlers.onGameChange(gameConfig)
  }

  return (
    <>
      <div className="optionsMenuButton" onClick={ toggleMenu }>...</div>
      { !state.showGameSettings || state.gameConfig === undefined ? null : (
        <GameSettings game={ state.gameConfig } onSave={ onSaveGameSettings } />
      )}
      { !state.showSaveLoad ? null : (
        <SaveLoadGame
          stateRef={ props.stateRef }
          onClose={ () => setState({ ...state, showSaveLoad: false }) }
          onLoad={ loaded => {
            setState({ ...state, showSaveLoad: false })
            props.actionHandlers.sendSync(loaded)
          }}
        />
      )}
    </>
  )
}
