import { useCallback, useEffect, useMemo, useRef } from "react";
import { CardData } from "common";
import MovableCollection from "../MovableCollections";
import { shouldShowFlipped } from "../Card";
import { addMouseEventListener } from "../../streams/utilities";
import { Subject } from "rxjs";
import { PlayAreaEvent } from "../../api/PlayAreaEvent";
import { createMouseStream } from "../../streams/mouseStreams";
import { useRecoilState } from "recoil";
import { positionState, scalesSelector } from "./atoms";
import GameCard from "../GameCard";

export interface ZoomProps {
  card: CardData,
  handId?: number,
  onHide: () => void
}

export default function Zoom(props: ZoomProps) {
  const { card, handId, onHide } = props
 
  const [scale, setScale] = useRecoilState(scalesSelector(card.dimensions))
  const [position, setPosition] = useRecoilState(positionState)
  const positionRef = useRef(position)
  positionRef.current = position

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const events$ = new Subject<PlayAreaEvent>()
    const { mouseMove$ } = createMouseStream(events$, () => 0, _ => ({ state: { x: 0, y: 0, z: 0, flipped: false, visible: false, focused: false } }))
    const sub = mouseMove$.subscribe(_ => {
      const newHeight = _.pageY - positionRef.current.y
      const scale = newHeight / card.dimensions.height
      setScale(scale)
    })

    const listenerCleanup = addMouseEventListener(0, ref.current!, false, events$)
    return () => {
      sub.unsubscribe()
      listenerCleanup()
    }
  }, [ref, card.dimensions, setScale])

  const ui = useMemo(() => (
    <>
      <button onClick={ onHide } style={ { position: 'absolute', top: -21 } }>Close</button>
      <GameCard
        dimensions={ { width: card.dimensions.width * scale, height: card.dimensions.height * scale } }
        layout={ card.layout }
        rotation={ undefined }
        useShadows={ true }
        focused={ false }
        flipped={ shouldShowFlipped(
          card.state.flipped,
          card.constraints.turnable,
          handId,
          card.state.handId
        ) }
        selected={ false }
        amendStyle={ {
          position: 'absolute',
          zIndex: 0
        } }
        text={card.layout.frontText}
        textStyle={card.layout.frontTextLayout}
      >
        <div ref={ ref } style={{ position: 'absolute', right: -10, bottom: -10, cursor: 'nw-resize' }} >O</div>
      </GameCard>
    </>
  ), [card.dimensions, card.layout, card.state.flipped, card.state.handId, card.constraints.turnable, handId, scale, onHide])

  const targetSelector = useCallback((_: HTMLElement) => _.parentElement, [])

  return (
    <MovableCollection
      state={ { items: [{ id: 0, state: { x: position.x, y: position.y, z: 999999, flipped: false, visible: true, focused: false } }] } }
      selector={ (_, __) => ui }
      targetSelector={ targetSelector }
      onStop={ (state, _) => {
        const { x, y } = state.items[0].state
        window.setTimeout(() => setPosition({ x, y }), 0)
      }}
    />
  )
}
