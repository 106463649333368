import { useCallback, useState } from "react";
import { useRef } from "react";
import { GameConfig } from "common";
import Card from "./Card";

interface IGameSettingsProps {
  game: GameConfig,
  onSave: (config: GameConfig) => void
}

export default function GameSettings(props: IGameSettingsProps) {

  const viewRef = useRef(props.game.decks.flatMap(d => d.cards))
  const getCardRef = useCallback((url, text) => {
    return viewRef.current.find(c => c.layout.front.image === url && c.layout.front.text === text)!
  }, [viewRef])

  const [, setState] = useState(true)

  const setCount = (url: string, text: string | undefined, count: number) => {
    getCardRef(url, text).count = Math.max(0, count)
    setState(s => !s)
  }

  const setPreset = useCallback((number) => {
    props.game.decks.forEach(deck => deck.cards.forEach(card => {
      const preset = card.preset !== undefined ? parseInt(card.preset.split(' ')[number]) : card.count
      getCardRef(card.layout.front.image, card.layout.front.text).count = Math.max(0, preset)
    }))
    setState(s => !s)
  }, [props.game.decks, getCardRef])

  const playerPreset = props.game.minPlayers === undefined ? null : (
    <div>
      <span>Players: </span>
      { Array.from(Array(props.game.maxPlayers! - props.game.minPlayers! + 1).keys()).map(i => (
        <button key={ i } onClick={ () => setPreset(i) } >{ i + props.game.minPlayers! }</button>
      )) }
    </div>
  )

  return (
    <div style={{ position: 'absolute', zIndex: 99999, padding: 20, backgroundColor: 'white', width: '100%' }}>
      <h1>{ props.game.title } <button onClick={ _ => props.onSave(props.game) }>Save</button></h1>
      { playerPreset }
      {
        viewRef.current.map(({ layout, count }, idx) => {
          const url = layout.front.image
          const text = layout.front.text
          return (
            <Card key={ `settings-${idx}` } url={ url } count={ count }
                  onPlus={ () => setCount(url, text, count + 1) }
                  onMinus={ () => setCount(url, text, count - 1) }
            />
          )
        })
      }
    </div>
  )
}
