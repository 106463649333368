import { MutableRefObject, useMemo, WheelEvent } from "react";
import { useRecoilValue } from "recoil";
import { Observable, merge, of, fromEvent } from "rxjs";
import { filter, map, switchMap, take, takeUntil, tap } from "rxjs/operators";
import LocalState from "../api/LocalState";
import OptionsData from "../api/OptionsData";
import { PlayAreaStreams } from "../api/PlayAreaStreams";
import userNameAtom from "../api/userName";
import { useOptions } from "../Components/Options/atom";
import { CardData, PlayAreaState, PlayAreaActionTypes, MOVE_KIND } from "common";
import { createDeck, flipCard, moveCardToTop, placeInDeck, removeFromDeck, mergeDeck, flipDeck, cardOwner, toggleHandOwner, cardDisown, cardHandOrder, moveTo, rotateCard, shuffleDeck, rollDie, drawFromPouch, focusCard } from "common";
import globalSubjects from "./globalSubjects";
import { getClosestCard, getDbClickStream, getDeckIdsForCardIds, getOverlappingHand, getTopLeftCornerPos, shuffleArray, tuple } from "./utilities";

function createCommonStreams(mouseStreams: PlayAreaStreams, stateRef: MutableRefObject<PlayAreaState>, localStateRef: MutableRefObject<LocalState>, userName: string, optionsRef: MutableRefObject<OptionsData>): Observable<PlayAreaActionTypes> {

  const {
    card$: {
     mouseDown$: cardMouseDown$,
     mouseMove$: cardMouseMove$,
     mouseUp$: cardMouseUp$
    },
    hand$: {
      mouseDown$: handMouseDown$,
      mouseMove$: handMouseMove$
    },
    die$: {
      mouseDown$: dieMouseDown$,
      mouseMove$: dieMouseMove$
    },
    pouch$: {
      mouseDown$: pouchMouseDown$,
      mouseMove$: pouchMouseMove$,
    }
  } = mouseStreams

  const keyDown$ = globalSubjects.playAreaKeyboardCard$
  const wheel$ = fromEvent<WheelEvent>(document, 'wheel', { passive: false })

  // --------------------------------------------------------------------------

  const cardMove$ = cardMouseMove$.pipe(
    filter(_ => _.element.constraints.movable), // todo: selection?
    map(event => {
      const { x, y } = getTopLeftCornerPos(event)
      const isCardMove = !event.longPressed || event.element.state.deckId === undefined
      if (isCardMove) {
        const selection = localStateRef.current.selection.cards
        const cardsToMove = selection.has(event.element.id) ? Array.from(selection) : undefined
        return moveTo(MOVE_KIND.CARD, event.element.id, x, y, cardsToMove)
      } else {
        return moveTo(MOVE_KIND.DECK, event.element.state.deckId!, x, y)
      }
    })
  )

  const dieMove$ = dieMouseMove$.pipe(
    map(event => {
      const { x, y } = getTopLeftCornerPos(event)
      const selection = localStateRef.current.selection.dice.has(event.id) ? Array.from(localStateRef.current.selection.dice) : undefined
      return moveTo(MOVE_KIND.DIE, event.element.id, x, y, selection)
    })
  )

  const pouchMove$ = pouchMouseMove$.pipe(
    filter(_ => _.longPressed),
    map(_ => {
      const { x, y } = getTopLeftCornerPos(_)
      return moveTo(MOVE_KIND.POUCH, _.id, x, y)
    })
  )

  const dieRoll$ = getDbClickStream(dieMouseDown$, () => optionsRef.current.m_dbClick.value, (a, b) => a.id === b.id).pipe(
    switchMap(_ => {
      const ids = localStateRef.current.selection.dice.has(_.id) ? Array.from(localStateRef.current.selection.dice) : [_.id]
      const rolls = ids.map(id => {
        const { current, sides } = stateRef.current.dice[id]

        // 25% chance to keep the new roll if it is the same as the old roll.
        const rand = new Uint16Array(1)
        window.crypto.getRandomValues(rand)
        const shouldKeepSameRoll = rand[0] % 4 === 0
        let roll = -1
        do {
          window.crypto.getRandomValues(rand)
          roll = rand[0] % sides.length
        } while (!shouldKeepSameRoll && roll === current)

        return rollDie(id, roll)
      })
      return of(...rolls)
    })
  )

  const handMove$ = handMouseMove$.pipe(
    map(event => {
      const { x, y } = getTopLeftCornerPos(event)
      return moveTo(MOVE_KIND.HAND, event.element.id, x, y)
    })
  )

  const cardMoveToTop$ = cardMouseDown$.pipe(
    filter(_ => _.element.constraints.topable),
    filter(event => {
      const zIndex = stateRef.current.zIndex
      return event.element.state.z !== zIndex
    }),
    map(event => moveCardToTop(event.element.id))
  )

  const cardRemoveFromDeck$ = cardMouseDown$.pipe(
    switchMap(_ => cardMouseMove$.pipe(
      take(1),
      filter(event => !event.longPressed && _.element.state.deckId !== undefined))
    ),
    map(event => removeFromDeck(event.element.id))
  )

  const drawFromPouch$ = pouchMouseDown$.pipe(
    switchMap(_ => pouchMouseMove$.pipe(
      take(1),
      filter(_ => !_.longPressed)
    )),
    map(_ => {
      const id = 1000 + Math.floor(Math.random() * 2000)
      const { x, y } = getTopLeftCornerPos(_)
      // TODO: haha
      window.setTimeout(() => globalSubjects.playAreaMouseCard$.next({
        ..._, id
      }), 10)
      return drawFromPouch(_.id, id, x, y)
    })
  )

  const flip$ = merge(
    getDbClickStream(cardMouseDown$, () => optionsRef.current.m_dbClick.value, (a, b) => !a.rightClick && !b.rightClick && a.element.id === b.element.id && a.element.state.deckId === b.element.state.deckId),
    keyDown$.pipe(filter(_ => !_.event.repeat && _.event.key.toLowerCase() === optionsRef.current.k_flip.value))
  ).pipe(
    map(_ => {
      const { id, state: { deckId, flipped } } = stateRef.current.cards[_.id]
      if (deckId !== undefined) {
        return flipDeck(deckId, !flipped)
      } else {
        const selectedCards = localStateRef.current.selection.cards
        const ids = selectedCards.has(id) ? Array.from(selectedCards) : [id]
        const filtered = ids.filter(id => stateRef.current.cards[id].constraints.turnable)
        return flipCard(filtered, !flipped)
      }
    })
  )

  const toggleHand$ = getDbClickStream(handMouseDown$, () => optionsRef.current.m_dbClick.value, (a, b) => a.element.id === b.element.id).pipe(
    map(event => toggleHandOwner(event.element.id, userName))
  )

  const cardOwn$ = cardMouseMove$.pipe(
    filter(_ => _.element.state.deckId === undefined),
    filter(_ => _.element.constraints.ownable),
    map(_ => {
      const hands = stateRef.current.hands
      return tuple(_, getOverlappingHand(_.element, hands))
    }),
    filter(_ => _.snd !== undefined && _.snd.id !== _.fst.element.state.handId),
    map(_ => cardOwner(_.fst.element.id, _.snd!.id))
  )

  const cardDisown$ = cardMouseMove$.pipe(
    filter(_ => _.element.state.handId !== undefined),
    map(_ => {
      const hands = stateRef.current.hands
      return tuple(_, getOverlappingHand(_.element, hands))
    }),
    filter(_ => _.snd === undefined || _.snd.id !== _.fst.element.state.handId),
    map(_ => cardDisown(_.fst.element.id))
  )

  const cardHandOrder$ = cardMouseUp$.pipe(
    filter(_ => _.element.state.handId !== undefined),
    map(_ => cardHandOrder(_.element.id))
  )

  const deckMerge$ = cardMouseUp$.pipe(
    filter(_ => _.element.state.deckId !== undefined),
    map(_ => {
      const cards = stateRef.current.cards
      const eFilter = (card: CardData) => card.state.deckId !== _.element.state.deckId!
      const closestCard = getClosestCard(_.element, cards, optionsRef.current.m_deckSnap.value, eFilter)
      return tuple(_, closestCard)
    }),
    filter(_ => _.snd !== undefined),
    filter(_ => _.fst.element.type === _.snd!.type),
    map(_ => mergeDeck(_.fst.element.state.deckId!, _.snd!.id))
  )

  const deckCreate$ = cardMouseUp$.pipe(
    filter(_ => _.element.state.deckId === undefined),
    map(_ => {
      const closesCard = getClosestCard(_.element, stateRef.current.cards, optionsRef.current.m_deckSnap.value)
      return tuple(_, closesCard)
    }),
    filter(_ => _.snd !== undefined && _.snd.state.handId === undefined),
    filter(_ => _.fst.element.type === _.snd!.type),
    map(_ => {
      const closestCard = _.snd!
      if (closestCard.state.deckId !== undefined) {
        return placeInDeck(_.fst.element.id, closestCard.state.deckId)
      } else {
        return createDeck(_.fst.element.id, closestCard.id)
      }
    })
  )

  const cardRotate$ = keyDown$.pipe(
    filter(_ => !_.event.repeat && _.event.key.toLowerCase() === optionsRef.current.k_rotate.value),
    filter(_ => stateRef.current.cards[_.id].state.deckId === undefined),
    map(_ => {
      const currentRotation = stateRef.current.cards[_.id].state.rotation ?? 0
      const newRotation = (currentRotation + 90) % 360
      return rotateCard(_.id, newRotation)
    })
  )

  const deckSortAsc$ = keyDown$.pipe(
    filter(_ => !_.event.repeat && _.event.key.toLowerCase() === optionsRef.current.k_sortAsc.value),
    filter(_ => stateRef.current.cards[_.id].state.deckId !== undefined),
    map(_ => {
      const deckId = stateRef.current.cards[_.id].state.deckId!
      const cardsInDeck = stateRef.current.decks[deckId].cards.map(_ => stateRef.current.cards[_])
      cardsInDeck.sort((a, b) => a.value - b.value)
      return shuffleDeck(cardsInDeck.map(_ => _.id))
    })
  )

  const deckSortDesc$ = keyDown$.pipe(
    filter(_ => !_.event.repeat && _.event.key.toLowerCase() === optionsRef.current.k_sortDesc.value),
    filter(_ => stateRef.current.cards[_.id].state.deckId !== undefined),
    map(_ => {
      const deckId = stateRef.current.cards[_.id].state.deckId!
      const cardsInDeck = stateRef.current.decks[deckId].cards.map(_ => stateRef.current.cards[_])
      cardsInDeck.sort((a, b) => b.value - a.value)
      return shuffleDeck(cardsInDeck.map(_ => _.id))
    })
  )

  const deckShuffle$ = keyDown$.pipe(
    filter(_ => !_.event.repeat && _.event.key.toLowerCase() === optionsRef.current.k_shuffle.value),
    filter(_ => stateRef.current.cards[_.id].state.deckId !== undefined),
    switchMap(_ => {
      const isSelected = localStateRef.current.selection.cards.has(_.id)
      const deckIds = getDeckIdsForCardIds(stateRef.current, isSelected ? Array.from(localStateRef.current.selection.cards) : [_.id])
      const shuffleActions = deckIds.map(id => {
        const cardsInDeck = [...stateRef.current.decks[id].cards]
        shuffleArray(cardsInDeck)
        return shuffleDeck(cardsInDeck)
      })
      return of(...shuffleActions)
    })
  )

  const cardGroup$ = keyDown$.pipe(
    filter(_ => !_.event.repeat && _.event.key.toLowerCase() === optionsRef.current.k_group.value),
    filter(_ => localStateRef.current.selection.cards.has(_.id)),
    switchMap(_ => {
      const selectedCards = Array.from(localStateRef.current.selection.cards).map(id => stateRef.current.cards[id])
      const groups = selectedCards
        .filter(card => card.state.deckId === undefined && card.state.handId === undefined)
        .reduce<{[key: number]: number[]}>((acc, curr) => {
          acc[curr.type] = (acc[curr.type] ?? [])
          acc[curr.type].push(curr.id)
          return acc
        }, {})

    return of(...Object.values(groups)
      .filter(group => group.length > 1)
      .map(group => createDeck(...group)))
    })
  )

  const cardFocus$ = cardMouseDown$.pipe(
    filter(_ => !_.rightClick),
    map(_ => {
      const selectedCards = localStateRef.current.selection.cards
      const ids = selectedCards.has(_.id) ? Array.from(selectedCards) : [_.id]
      return focusCard(ids, true)
    })
  )

  const cardUnFocus$ = cardMouseUp$.pipe(
    filter(_ => !_.rightClick),
    map(_ => {
      const selectedCards = localStateRef.current.selection.cards
      const ids = selectedCards.has(_.id) ? Array.from(selectedCards) : [_.id]
      return focusCard(ids, false)
    })
  )

  const cardRotateOnWheel$ = cardMouseDown$.pipe(
    filter(_ => !_.rightClick && optionsRef.current.t_rotateOnWheel.value),
    switchMap(cardEvent => wheel$.pipe(
      tap(_ => _.preventDefault()),
      map(_ => {
        const amount = optionsRef.current.m_rotationAmount
        const multiplier = _.deltaY < 0 ? -1 : 1
        const currentRotation = stateRef.current.cards[cardEvent.id].state.rotation ?? 0
        return rotateCard(cardEvent.id, currentRotation + amount.value * multiplier)
      }),
      takeUntil(cardMouseUp$)
    ))
  )

  // cardDisown must come before cardOwn
  // cardDisown & cardOwn must come before deckMerge & deckCreate
  return merge(cardMove$, handMove$, dieMove$, pouchMove$, cardFocus$, cardUnFocus$, toggleHand$, cardMoveToTop$, cardRemoveFromDeck$, flip$, cardDisown$, cardOwn$, cardHandOrder$, deckMerge$, deckCreate$, cardRotate$, deckSortAsc$, deckSortDesc$, deckShuffle$, cardGroup$, dieRoll$, drawFromPouch$, cardRotateOnWheel$)
}

export default function useCommonStreams(mouseStreams: PlayAreaStreams, stateRef: MutableRefObject<PlayAreaState>, localStateRef: MutableRefObject<LocalState>): Observable<PlayAreaActionTypes> {
  const optionsRef = useOptions()
  const userName = useRecoilValue(userNameAtom)
  return useMemo(() => {
    return createCommonStreams(mouseStreams, stateRef, localStateRef, userName, optionsRef)
  }, [mouseStreams, stateRef, localStateRef, userName, optionsRef])
}
