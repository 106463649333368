import { CSSProperties, useCallback, useEffect, useMemo, useRef } from 'react'
import { HandData } from 'common'
import { LocalActionHandlers } from '../../Pages/PlayArea/actionHandlers'
import globalSubjects from '../../streams/globalSubjects'
import { addMouseEventListener } from '../../streams/utilities'
import './hand.css'

interface IHand {
  hand: HandData,
  ownedByMe: boolean,
  actions: LocalActionHandlers
}

// TODO: use movable to wrap Hand?
export default function Hand(props: IHand) {
  const { id, state, layout, dimensions } = props.hand
  const { x, y, z, owners } = state
  const { frontColor } = layout
  const actions = props.actions

  const ref = useRef<HTMLDivElement>(null)
  const onShuffle = useCallback(() => actions.onShuffleHand(id), [actions, id])
  useEffect(() => addMouseEventListener(id, ref.current!, false, globalSubjects.playAreaHand$), [id])
  
  const css: CSSProperties = useMemo(() => ({
    ...dimensions,
    display: 'grid'
  }), [dimensions])

  return (
    <div className='handContainer' style={{ left: x, top: y, zIndex: z }}>
      <div style={ css }>

        <span ref={ ref } className='handOwner' style={{ backgroundColor: frontColor }}>
        { owners.join(', ') }
        </span>

        { !props.ownedByMe ? null : (
        <div onClick={ onShuffle } className='handShuffle'>
          Shuffle
        </div>
        )}

      </div>
    </div>
  )
}
