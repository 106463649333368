import { useRecoilValue } from "recoil";
import contextMenuAtom from "./atom";
import { ContextMenuItem } from "./groups";
import "./style.css";

export default function ContextMenu() {
  const contextMenu = useRecoilValue(contextMenuAtom)
  if (contextMenu === undefined) {
    return null
  }


  const onClick = (event: React.MouseEvent, item: ContextMenuItem) => {
    if (!item.close) {
      event.stopPropagation()
    }
    item.action()
  }

  const onBgClick = (event: React.MouseEvent) => {
    if ((event.target as HTMLElement).innerHTML === contextMenu.group.title) {
      event.stopPropagation()
    }
  }

  return (
    <div onMouseDown={ onBgClick } className="contextMenu" style={{ left: contextMenu.x, top: contextMenu.y }}>
      <div>{ contextMenu.group.title }</div>
      <ul>
        { contextMenu.group.items.map((item, idx) => (
          <li key={ idx } onMouseDown={ event => onClick(event, item) } >{ item.text }</li>
        )) }
      </ul>
    </div>
  )
}
