import * as T from "./localActionTypes"

export function createSelection(cardSelection: number[], deckSelection: number[], dieSelection: number[]): T.LocalActionTypes {
  return { type: T.CREATE_SELECTION, cardSelection, deckSelection, dieSelection }
}

export function showDeck(deckId?: number): T.LocalActionTypes {
  return { type: T.SHOW_DECK, deckId } 
}

export function zoomOnCard(cardId?: number): T.LocalActionTypes {
  return { type: T.ZOOM_ON_CARD, cardId }
}

export function noop(): T.LocalActionTypes {
  return { type: T.NOOP }
}
