import { useEffect, useMemo, useRef } from "react";
import { Subject } from "rxjs";
import Card, { shouldShowFlipped } from "../../Components/Card";
import Hand from "../../Components/Hand";
import { PlayAreaActionTypes, createDefaultPlayAreaState, createPlayAreaReducer } from "common";
import DeckView from "../../Components/DeckView";
import { useLocation } from "react-router-dom";
import { PLAYER_JOINED } from "../../Components/Actions/playerActionTypes";
import Selection from "../../Components/Selection";
import localReducer from "./localReducer";
import { useActionHandlers } from "./actionHandlers";
import Die from "../../Components/Die";
import ContextMenu from "../../Components/ContextMenu";
import Zoom from "../../Components/Zoom";
import Menu from "../../Components/Menu";
import Pouch from "../../Components/Pouch";
import Options from "../../Components/Options";
import { useImmerReducer } from "use-immer";
import { defaultLocalState } from "../../api/LocalState";
import useLocalStreams from "../../streams/localStreams";
import usePlayAreaStreams from "../../streams/playAreaStreams";
import useCommonStreams from "../../streams/commonStreams";
import createWebSocket from "../../streams/socketIO";
import createWebSocketStreams from "../../streams/webSocketStreams";
import { useRecoilValue } from "recoil";
import userNameAtom from "../../api/userName";

const defaultPlayAreaState = createDefaultPlayAreaState()

export default function PlayArea() {

  const location = useLocation()
  const roomCode = useMemo(() => (location.search.match('c=(.*)') ?? ['','INVALID'])[1], [location.search])
  const username = useRecoilValue(userNameAtom)

  const actions$ = useMemo(() => new Subject<PlayAreaActionTypes>(), [])

  const reducer = useMemo(() => createPlayAreaReducer(() => alert("SYNC MAY BE REQUIRED!")), [])
  const [state, dispatch] = useImmerReducer(reducer, defaultPlayAreaState)
  const { cards, dice, decks, hands, pouches } = state
  const stateRef = useRef(state)
  stateRef.current = state

  const [localState, localDispatch] = useImmerReducer(localReducer, defaultLocalState)
  const localStateRef = useRef(localState)
  localStateRef.current = localState

  const actionHandlers = useActionHandlers(actions$, localDispatch, stateRef)

  //const myHandId = localState.handId
  const myHandId = useMemo(() => Object.values(hands).find(h => h.state.owners.indexOf(username) > -1)?.id, [username, hands])
  
  const mouseStreams = usePlayAreaStreams(stateRef)
  const common$ = useCommonStreams(mouseStreams, stateRef, localStateRef)
  const local$ = useLocalStreams(mouseStreams, stateRef, localStateRef, actionHandlers)

  useEffect(() => {
    const [ playIn$, playOut$, event$ ] = createWebSocket(roomCode)
    const { wsIn$, wsOut$ } = createWebSocketStreams(playIn$, playOut$)
    const subs = [
      wsIn$.subscribe(dispatch),
      common$.subscribe(_ => actions$.next(_)),
      actions$.subscribe(_ => wsOut$.next(_)),
      actions$.subscribe(dispatch),
      local$.subscribe(localDispatch),
      event$.subscribe(event => {
        if (event.type === PLAYER_JOINED) {
          //actionHandlers.onSync()
        }
      })
    ]
    return () => subs.forEach(sub => sub.unsubscribe())
  }, [actions$, dispatch, localDispatch, stateRef, roomCode, actionHandlers, local$, common$, mouseStreams])

  const cardsInDeckView = useMemo(() => {
    if (localState.showDeck === undefined) {
      return null
    }
    return decks[localState.showDeck].cards.map(cardId => cards[cardId])
  }, [localState.showDeck, decks])

  const deckViewUI = localState.showDeck === undefined ? null : (
    <DeckView
      cards={ cardsInDeckView! }
      onClose={ actionHandlers.onHideDeck }
      onSave={ actionHandlers.onShuffleDeck }
    />
  )

  const zoomOnCardUI = localState.zoonOnCard === undefined ? null : (
    <Zoom
      card={ stateRef.current.cards[localState.zoonOnCard] }
      handId={ myHandId }
      onHide={ actionHandlers.onHideZoomedCard }
    />
  )

  return (
    <>
      <Menu actionHandlers={ actionHandlers } stateRef={ stateRef } />
      <ContextMenu />
      <Selection playAreaState={ stateRef } onSelected={ actionHandlers.onSelected } />
      <Options />
      { deckViewUI }
      { zoomOnCardUI }
      {
        Object.values(cards).map(card => !card.state.visible ? null : (
          <Card
            actions={ actionHandlers }
            selected={ localState.selection.cards.has(card.id) }
            key={ card.id }
            card={ card }
            deck={ card.state.deckId === undefined ? undefined : decks[card.state.deckId] }
            showFlipped={ shouldShowFlipped(card.state.flipped, card.constraints.turnable, myHandId, card.state.handId) }
          />
        ))
      }
      {
        Object.values(hands).map(hand => (
          <Hand
            key={hand.id}
            hand={ hand }
            actions={ actionHandlers }
            ownedByMe={ myHandId !== undefined && myHandId === hand.id }
          />
        ))
      }
      {
        dice.map(die => !die.state.visible ? null : (
          <Die
            key={ die.id }
            die={ die }
            selected={ localState.selection.dice.has(die.id) }
          />
        ))
      }
      {
        pouches.map(pouch => !pouch.state.visible ? null : (
          <Pouch key={ pouch.id } template={ pouch } />
        ))
      }
    </>
  )
}
