"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPlayAreaReducer = void 0;
var PlayAreaState_1 = require("../api/PlayAreaState");
var HandData_1 = require("../api/HandData");
var GamesHelper_1 = require("../api/GamesHelper");
var T = __importStar(require("./playAreaActionTypes"));
var utilities_1 = require("../utilities");
function createPlayAreaReducer(syncHandler) {
    function orderCardsInHand(cards, hands, handId) {
        var cardsInHand = hands[handId].state.cards;
        var zIndices = cardsInHand.map(function (id) { return cards[id].state.z; }).sort(function (a, b) { return a - b; });
        cardsInHand.sort(function (a, b) { return cards[a].state.x - cards[b].state.x; });
        var padding = 20;
        var _a = hands[handId].state, x = _a.x, y = _a.y;
        var _b = Object.values(cards)[0].dimensions, width = _b.width, height = _b.height;
        var handWidth = width + padding + Math.max(0, cardsInHand.length - 1) * (width / 2);
        hands[handId].dimensions.width = handWidth;
        (0, utilities_1.zip)(cardsInHand, zIndices).forEach(function (_a, idx) {
            var cardId = _a[0], zIndex = _a[1];
            cards[cardId].state.x = x + idx * (width / 2) + (padding / 2);
            cards[cardId].state.y = y + 10 - height;
            cards[cardId].state.z = zIndex;
        });
    }
    function moveItem(ids, items, x, y, target) {
        if (ids.length === 0) {
            return;
        }
        var _a = (0, utilities_1.clampPos)(x, y), clampX = _a.clampX, clampY = _a.clampY;
        var _b = target.state, currX = _b.x, currY = _b.y;
        var _c = [currX - clampX, currY - clampY], dx = _c[0], dy = _c[1];
        ids.forEach(function (id) {
            var _a = (0, utilities_1.clampPos)(items[id].state.x - dx, items[id].state.y - dy), clampX = _a.clampX, clampY = _a.clampY;
            items[id].state.x = clampX;
            items[id].state.y = clampY;
        });
    }
    function moveTo(state, action) {
        var id = action.id, kind = action.kind, x = action.x, y = action.y, selection = action.selection;
        switch (kind) {
            case T.MOVE_KIND.CARD:
                moveItem(selection !== null && selection !== void 0 ? selection : [id], state.cards, x, y, state.cards[id]);
                return;
            case T.MOVE_KIND.DIE:
                moveItem(selection !== null && selection !== void 0 ? selection : [id], state.dice, x, y, state.dice[id]);
                return;
            case T.MOVE_KIND.POUCH:
                moveItem([id], state.pouches, x, y, state.pouches[id]);
                return;
            case T.MOVE_KIND.DECK:
                if (state.decks[id] === undefined) {
                    syncHandler();
                    console.warn("[moveTo][DECK] Tried to move non-existent deck (".concat(id, ")"));
                    return;
                }
                var cardsInDeck = state.decks[id].cards;
                moveItem(cardsInDeck, state.cards, x, y, state.cards[cardsInDeck[0]]);
                return;
            case T.MOVE_KIND.HAND:
                moveItem(state.hands[id].state.cards, state.cards, x, y, state.hands[id]);
                moveItem([id], state.hands, x, y, state.hands[id]);
                return;
        }
    }
    function moveCardToTop(state, _a) {
        var id = _a.id;
        if (state.cards[id].state.z !== state.zIndex) {
            state.cards[id].state.z = state.zIndex + 1;
            state.zIndex += 1;
        }
    }
    function flipCard(state, _a) {
        var ids = _a.ids, flipped = _a.flipped;
        ids.forEach(function (id) { return state.cards[id].state.flipped = flipped; });
    }
    function flipDeck(state, _a) {
        var id = _a.id, flipped = _a.flipped;
        if (state.decks[id] === undefined) {
            syncHandler();
            console.warn("[flipDeck] Tried to flip non-existent deck (".concat(id, ")"));
            return;
        }
        var cardsInDeck = state.decks[id].cards;
        var reversedCardsInDeck = __spreadArray([], cardsInDeck, true).reverse();
        (0, utilities_1.zip)(cardsInDeck, reversedCardsInDeck).forEach(function (_a) {
            var i = _a[0], j = _a[1];
            var z = state.cards[j].state.z;
            var _flipped = state.cards[i].state.flipped;
            if (state.cards[i].constraints.turnable) {
                _flipped = flipped === state.cards[i].state.flipped ? !flipped : flipped;
            }
            state.cards[i].state.z = z;
            state.cards[i].state.flipped = _flipped;
            state.cards[i].state.visible = false;
        });
        state.decks[id].cards = reversedCardsInDeck;
        state.cards[reversedCardsInDeck[0]].state.visible = true;
    }
    function removeFromDeck(state, _a) {
        var id = _a.id;
        var deckId = state.cards[id].state.deckId;
        if (deckId === undefined) {
            // TODO: silence it?
            syncHandler();
            console.warn("[removeFromDeck] card (".concat(id, ") has no deck id"));
            return;
        }
        state.cards[id].state.deckId = undefined;
        state.decks[deckId].cards.shift();
        if (state.decks[deckId].cards.length > 0) { // workaround for 'game helper' not beeing able to render single cards, only decks from config
            var topCardId = state.decks[deckId].cards[0];
            state.cards[topCardId].state.visible = true;
        }
        if (state.decks[deckId].cards.length === 1) {
            var lastCardId = state.decks[deckId].cards[0];
            state.cards[lastCardId].state.deckId = undefined;
            delete state.decks[deckId];
        }
    }
    function createDeck(state, action) {
        var _a = action.cardIds, rest = _a.slice(1);
        var _b = state.cards[rest[0]].state, x = _b.x, y = _b.y;
        var deckId = state.deckId + 1;
        state.deckId += 1;
        action.cardIds.forEach(function (id) {
            state.cards[id].state.x = x;
            state.cards[id].state.y = y;
            state.cards[id].state.deckId = deckId;
            state.cards[id].state.visible = false;
        });
        state.cards[action.cardIds[0]].state.visible = true;
        state.decks[deckId] = {
            id: deckId,
            cards: __spreadArray([], action.cardIds, true)
        };
    }
    // TODO: place in deck with placement index? can we use this for deck->card merge?
    function placeInDeck(state, _a) {
        var cardId = _a.cardId, deckId = _a.deckId;
        var referenceCard = Object.values(state.cards).find(function (card) { return card.state.deckId === deckId; });
        if (referenceCard === undefined) {
            syncHandler();
            console.warn("[placeInDeck] Tried to place card (".concat(cardId, ") in non-existent deck (").concat(deckId, ")"));
            return;
        }
        var _b = referenceCard.state, x = _b.x, y = _b.y;
        state.cards[cardId].state.x = x; // = copyState(cards[cardId], { x, y, deckId })
        state.cards[cardId].state.y = y;
        state.cards[cardId].state.deckId = deckId;
        var cardsInDeck = state.decks[deckId].cards;
        var topCardId = cardsInDeck[0];
        state.cards[topCardId].state.visible = false;
        state.decks[deckId].cards.unshift(cardId);
    }
    function mergeDeck(state, _a) {
        var deckId = _a.deckId, targetCardId = _a.targetCardId;
        var _b = state.cards[targetCardId].state, targetDeckId = _b.deckId, x = _b.x, y = _b.y;
        var cardId = state.cards[targetCardId].id;
        var cardsInTarget = (targetDeckId !== undefined && targetDeckId in state.decks) ? state.decks[targetDeckId].cards : [cardId];
        var topCardId = cardsInTarget[0];
        state.cards[topCardId].state.visible = false;
        var cardIds = state.decks[deckId].cards;
        state.decks[deckId].cards = __spreadArray(__spreadArray([], cardIds, true), cardsInTarget, true);
        if (targetDeckId !== undefined) {
            delete state.decks[targetDeckId];
        }
        cardsInTarget.forEach(function (id) { return state.cards[id].state.deckId = deckId; });
        var reversed = __spreadArray([], cardIds, true).reverse();
        reversed.forEach(function (id, idx) {
            state.cards[id].state.x = x;
            state.cards[id].state.y = y;
            state.cards[id].state.z = state.zIndex + idx + 1;
        });
        state.zIndex += cardIds.length + 1;
    }
    function cardOwner(state, _a) {
        var cardId = _a.cardId, handId = _a.handId;
        state.cards[cardId].state.handId = handId;
        var cardsInHand = state.hands[handId].state.cards;
        if (cardsInHand.indexOf(cardId) < 0) {
            cardsInHand.push(cardId);
        }
        // turn marker
        if (cardId === 0) {
            state.hands[handId].layout.frontColor = 'red';
        }
    }
    function cardDisown(state, _a) {
        var cardId = _a.cardId;
        var handId = state.cards[cardId].state.handId;
        if (handId === undefined) {
            // TODO: silence it?
            syncHandler();
            console.warn("[cardDisown] card (".concat(cardId, ") has no hand id"));
            return;
        }
        state.cards[cardId].state.handId = undefined;
        var cardsInHand = state.hands[handId].state.cards;
        var idx = cardsInHand.indexOf(cardId);
        if (idx < 0) {
            syncHandler();
            console.warn("[cardDisown] card (".concat(cardId, ") is not in previous owner hand"));
            return;
        }
        cardsInHand.splice(idx, 1);
        // turn marker
        if (cardId === 0) {
            state.hands[handId].layout.frontColor = 'white';
        }
        orderCardsInHand(state.cards, state.hands, handId);
    }
    function cardHandOrder(state, _a) {
        var cardId = _a.cardId;
        var handId = state.cards[cardId].state.handId;
        if (handId === undefined) {
            syncHandler();
            console.warn("[cardHandOrder] card (".concat(cardId, ") does not have hand id"));
            return;
        }
        orderCardsInHand(state.cards, state.hands, handId);
    }
    // obsolete
    // why?
    // i don't know
    function toggleHand(state, _a) {
        var id = _a.id, name = _a.name;
        var owners = state.hands[id].state.owners;
        if (owners.indexOf(name) > -1) {
            owners.splice(owners.indexOf(name), 1);
        }
        else {
            owners.push(name);
        }
        var oldHand = Object.values(state.hands).find(function (hand) { return hand.id !== id && hand.state.owners.indexOf(name) > -1; });
        if (oldHand !== undefined) {
            var nameIndex = oldHand.state.owners.indexOf(name);
            oldHand.state.owners.splice(nameIndex, 1);
        }
    }
    function shuffleDeck(state, _a) {
        var cardOrder = _a.cardOrder;
        var reversed = __spreadArray([], cardOrder, true).reverse();
        reversed.forEach(function (id, idx) {
            state.cards[id].state.z = state.zIndex + idx + 1;
            state.cards[id].state.visible = false;
        });
        var topCardId = cardOrder[0];
        state.cards[topCardId].state.visible = true;
        var deckId = state.cards[cardOrder[0]].state.deckId;
        if (deckId === undefined) {
            syncHandler();
            console.warn("[shuffleDeck] could not find associated deck");
            return;
        }
        state.decks[deckId].cards = __spreadArray([], cardOrder, true);
        state.zIndex += cardOrder.length + 1;
    }
    function shuffleHand(state, _a) {
        var cardOrder = _a.cardOrder;
        var cardsInHand = cardOrder.map(function (idx) { return state.cards[idx]; });
        var zs = cardsInHand.map(function (card) { return card.state.z; }).sort(function (a, b) { return a - b; });
        var xs = cardsInHand.map(function (card) { return card.state.x; }).sort(function (a, b) { return a - b; });
        cardsInHand.forEach(function (card, idx) {
            state.cards[card.id].state.x = xs[idx];
            state.cards[card.id].state.z = zs[idx];
        });
    }
    function rotateCard(state, _a) {
        var id = _a.id, rotation = _a.rotation;
        state.cards[id].state.rotation = rotation % 360;
    }
    function rollDie(state, _a) {
        var id = _a.id, roll = _a.roll;
        state.dice[id].current = roll;
    }
    function addHand(state, _a) {
        var id = _a.id, name = _a.name, x = _a.x, y = _a.y;
        var displayName = Object.values(state.hands).find(function (hand) { return hand.state.owners.includes(name); }) === undefined
            ? name
            : undefined;
        state.hands[id] = (0, HandData_1.createHand)(id, displayName, x, y);
    }
    function deleteHand(state, _a) {
        var id = _a.id;
        state.hands[id].state.cards.forEach(function (cardId) {
            state.cards[cardId].state.handId = undefined;
        });
        delete state.hands[id];
    }
    function drawFromPouch(state, _a) {
        var pouchId = _a.pouchId, newId = _a.newId;
        state.cards[newId] = __assign(__assign({}, state.pouches[pouchId]), { id: newId, state: __assign(__assign({}, state.pouches[pouchId].state), { z: state.zIndex + 1 }) });
        state.zIndex += 1;
    }
    function lockCard(state, _a) {
        var cardId = _a.cardId, lockState = _a.state;
        state.cards[cardId].constraints.movable = lockState;
    }
    function focusCard(state, _a) {
        var ids = _a.ids, focusState = _a.state;
        ids.forEach(function (id) {
            state.cards[id].state.focused = focusState;
        });
    }
    function toggleTurnMarker(state, _a) {
        var toggle = _a.toggle;
        if (toggle) {
            state.cards[0] = (0, GamesHelper_1.createTurnMarker)();
        }
        else {
            var handWithTurnMarker = Object.values(state.hands).find(function (hand) { return hand.state.cards.includes(0); });
            if (handWithTurnMarker !== undefined) {
                var idx = handWithTurnMarker.state.cards.indexOf(0);
                handWithTurnMarker.layout.frontColor = 'white';
                handWithTurnMarker.state.cards.splice(idx, 1);
                orderCardsInHand(state.cards, state.hands, handWithTurnMarker.id);
            }
            delete state.cards[0];
        }
    }
    function selectGame(state, _a) {
        var config = _a.config;
        var _b = (0, GamesHelper_1.loadGame)(config), cards = _b.cards, decks = _b.decks, dice = _b.dice, pouches = _b.pouches;
        Object.values(state.hands).forEach(function (hand) {
            hand.state.cards = [];
            hand.layout.frontColor = 'white';
            hand.dimensions.width = 100;
            hand.dimensions.height = 35;
        });
        state.cards = cards;
        state.decks = decks;
        state.dice = dice;
        state.pouches = pouches;
        state.zIndex = Object.keys(cards).length + Object.keys(state.hands).length;
        state.deckId = Object.keys(decks).length + 1;
    }
    return function playAreaReducer(state, action) {
        if (state === void 0) { state = (0, PlayAreaState_1.createDefaultPlayAreaState)(); }
        if (Object.values(state.cards).length === 0 && action.type !== T.SYNC_STATE && action.type !== T.SELECT_GAME) {
            return;
        }
        switch (action.type) {
            case T.MOVE_TO:
                moveTo(state, action);
                break;
            case T.BATCH_MOVE_TO:
                moveTo(state, {
                    type: 'MOVE_TO',
                    kind: action.kind,
                    id: action.id,
                    x: action.toX,
                    y: action.toY,
                    selection: action.selection
                });
                break;
            case T.MOVE_CARD_TO_TOP:
                moveCardToTop(state, action);
                break;
            case T.FLIP_CARD:
                flipCard(state, action);
                break;
            case T.FLIP_DECK:
                flipDeck(state, action);
                break;
            case T.REMOVE_FROM_DECK:
                removeFromDeck(state, action);
                break;
            case T.CREATE_DECK:
                createDeck(state, action);
                break;
            case T.PLACE_IN_DECK:
                placeInDeck(state, action);
                break;
            case T.MERGE_DECK:
                mergeDeck(state, action);
                break;
            case T.CARD_OWNER:
                cardOwner(state, action);
                break;
            case T.CARD_DISOWN:
                cardDisown(state, action);
                break;
            case T.CARD_HAND_ORDER:
                cardHandOrder(state, action);
                break;
            case T.TOGGLE_HAND:
                toggleHand(state, action);
                break;
            case T.SHUFFLE_DECK:
                shuffleDeck(state, action);
                break;
            case T.SHUFFLE_HAND:
                shuffleHand(state, action);
                break;
            case T.ROTATE_CARD:
                rotateCard(state, action);
                break;
            case T.ROLL_DIE:
                rollDie(state, action);
                break;
            case T.ADD_HAND:
                addHand(state, action);
                break;
            case T.DELETE_HAND:
                deleteHand(state, action);
                break;
            case T.DRAW_FROM_POUCH:
                drawFromPouch(state, action);
                break;
            case T.LOCK_CARD:
                lockCard(state, action);
                break;
            case T.SELECT_GAME:
                selectGame(state, action);
                break;
            case T.FOCUS_CARD:
                focusCard(state, action);
                break;
            case T.HAND_COLOR:
                state.hands[action.id].layout.frontColor = action.color;
                break;
            case T.TOGGLE_TURN_MARKER:
                toggleTurnMarker(state, action);
                break;
            case T.SYNC_STATE:
                return action.state;
            default:
                break;
        }
    };
}
exports.createPlayAreaReducer = createPlayAreaReducer;
