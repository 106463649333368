"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHand = void 0;
function createHand(id, name, x, y) {
    return {
        id: id,
        type: -1,
        state: {
            x: x,
            y: y,
            z: 1,
            flipped: false,
            visible: true,
            cards: [],
            focused: false,
            owners: name === undefined ? [] : [name]
        },
        dimensions: {
            width: 100,
            height: 35
        },
        layout: {
            frontColor: 'white'
        },
        constraints: {
            movable: true,
            turnable: false,
            ownable: true,
            topable: false
        }
    };
}
exports.createHand = createHand;
