export default interface LocalState {
  handId?: number,
  selection: {
    cards: Set<number>,
    decks: Set<number>,
    dice: Set<number>
  },
  showDeck?: number,
  showOptionsMenu: boolean,
  zoonOnCard?: number,
}

export const defaultLocalState : LocalState = {
  handId: undefined,
  selection: {
    cards: new Set(),
    decks: new Set(),
    dice: new Set()
  },
  showOptionsMenu: false,
  showDeck: undefined,
  zoonOnCard: undefined
}
