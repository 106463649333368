import { PlayAreaState } from "common";
import React, { MutableRefObject, useEffect, useState } from "react"

interface SaveLoadGameProps {
  stateRef: MutableRefObject<PlayAreaState>,
  onClose: () => void,
  onLoad: (state: PlayAreaState) => void
}

export default function SaveLoadGame(props: SaveLoadGameProps) {
  const [value, setValue] = useState('');
  const setValueHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    setValue(JSON.stringify(props.stateRef.current))
  }, [props.stateRef])

  return (
    <div style={{ position: 'absolute', zIndex: 99999, padding: 10 }}>
      <div>
        <button onClick={ props.onClose }>Close</button>
        <button onClick={ () => props.onLoad(JSON.parse(value)) }>Load JSON</button>
      </div>
      <textarea
        cols={ 50 }
        rows={ 10 }
        value={ value }
        onChange={ setValueHandler }
      />
    </div>
  )
}
