import { MutableRefObject, useEffect, useRef } from "react"
import { atom, useRecoilValue } from "recoil"
import OptionsData, { defaultOptions } from "../../api/OptionsData"

const optionsAtom = atom<OptionsData>({
  key: 'optionsAtom',
  default: defaultOptions
})

export default optionsAtom

export function useOptions(): MutableRefObject<OptionsData> {
  const options = useRecoilValue(optionsAtom)
  const optionsRef = useRef(options)
  useEffect(() => {
    optionsRef.current = options
  }, [optionsRef, options])

  return optionsRef
}
