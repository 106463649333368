import { useEffect, useMemo, useRef } from "react";
import { map, Subject } from "rxjs";
import { ItemState } from "common";
import { PlayAreaEvent } from "../../api/PlayAreaEvent";
import Movable from "../Movable";
import { createMouseStream } from "../../streams/mouseStreams";
import { getTopLeftCornerPos } from "../../streams/utilities";
import { Reducer, useImmerReducer } from "use-immer";
import { Draft } from "immer";

export type ItemType = { id: number, state: ItemState }

export interface DefaultState {
  items: ItemType[]
}

function getDefaultReducer<S extends DefaultState>(
  onMove?: (state: Draft<S>, id: number) => void,
  onStop?: (state: Draft<S>, id: number) => void
): Reducer<S, any> {
  return (state, action) => {
    const { id, x, y } = action
    const index = state.items.findIndex(item => item.id === id)!
    switch (action.type) {
      case 'MOVE':
        state.items[index].state.x = x
        state.items[index].state.y = y
        state.items[index].state.z = 99999
        onMove?.(state, id)
        break
      case 'STOP':
        state.items[index].state.z = 99998
        onStop?.(state, id)
    }
  }
}

export interface MovableProps<S extends DefaultState> {
  state: S,
  selector: (state: S, id: number) => JSX.Element,
  onMove?: (state: Draft<S>, id: number) => void,
  onStop?: (state: Draft<S>, id: number) => void,
  targetSelector?: (target: HTMLElement) => HTMLElement | null
}

export default function MovableCollection<S extends DefaultState>(props: MovableProps<S>) {
  const events$ = useMemo(() => new Subject<PlayAreaEvent>(), [])
  const [state, dispatch] = useImmerReducer(getDefaultReducer(props.onMove, props.onStop), props.state)
  const stateRef = useRef(state)
  stateRef.current = state

  useEffect(() => {
    const { mouseMove$, mouseUp$ } = createMouseStream(events$, () => 0, id => stateRef.current.items.find(item => item.id === id)!)

    mouseMove$.pipe(map(event => ({
        type: 'MOVE',
        id: event.id,
        ...getTopLeftCornerPos(event)
    }))).subscribe(dispatch)

    mouseUp$.pipe(map(event => ({
      type: 'STOP',
      id: event.id
    }))).subscribe(dispatch)

  }, [events$, dispatch])
  
  return (
    <>
    {
      state.items.map(item => (
        <Movable key={ item.id } id={ item.id } state={ item.state } mouse$={ events$ } targetSelector={ props.targetSelector }>
          { props.selector(state, item.id) }
        </Movable>
      ))
    }
    </>
  )
}
