import { CSSProperties, ReactNode } from "react";
import StaticCard, { StaticCardProps } from "../StaticCard";

export interface GameCardProps extends StaticCardProps {
  text?: string
  textStyle?: CSSProperties,
  children?: ReactNode
}

export default function GameCard(props: GameCardProps): JSX.Element {
  return (
    <StaticCard
      onMouseEnter={ props.onMouseEnter }
      onMouseLeave={ props.onMouseLeave }
      dimensions={ props.dimensions }
      layout={ props.layout }
      rotation={ props.rotation }
      focused={ props.focused }
      flipped={ props.flipped }
      selected={ props.selected }
      useShadows={ props.useShadows }
    >
      <>
      { props.flipped || props.text === undefined ? null : (
        <span className='cardText' style={ props.textStyle }>
          { props.text }
        </span>
      )}
      { props.children }
      </>
    </StaticCard>
  )
}
