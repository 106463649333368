import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useOptions } from "./Components/Options/atom";
import Home from "./Pages/Home";
import PlayArea from "./Pages/PlayArea/PlayArea";

export default function App() {
  const backgroundColor = useOptions().current.k_backgroundColor.value
  useEffect(() => {
    window.addEventListener('contextmenu', _ => {
      _.preventDefault()
      return false
    })
    document.body.style.backgroundColor = backgroundColor
  }, [backgroundColor])

  return(
    <Router>
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/playarea" element={ <PlayArea /> } />
      </Routes>
    </Router>
  );
}
