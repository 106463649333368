"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clampPos = exports.zip = void 0;
function zip(a, b) {
    return Array.from(Array(Math.max(b.length, a.length)), function (_, i) { return [a[i], b[i]]; });
}
exports.zip = zip;
function clampPos(x, y, min) {
    if (min === void 0) { min = 0; }
    return {
        clampX: Math.max(min, x),
        clampY: Math.max(0, y)
    };
}
exports.clampPos = clampPos;
function groupIntoSubLists(list, size) {
    return Array.from({ length: Math.floor(list.length / size) }, function () { return list.splice(0, size); });
}
exports.default = groupIntoSubLists;
