import './movable.css'
import { ReactNode, useEffect, useRef } from 'react'
import { addMouseEventListener, addKeyboardEventListener } from '../../streams/utilities'
import { KeyboardPlayAreaEvent, PlayAreaEvent } from '../../api/PlayAreaEvent'
import { ItemState } from 'common'
import { Subject } from 'rxjs'

interface MovableProps {
  id: number,
  state: ItemState
  mouse$: Subject<PlayAreaEvent>,
  keyboard$?: Subject<KeyboardPlayAreaEvent>,
  targetSelector?: (element: HTMLElement) => HTMLElement | null,
  offset?: number,
  children?: ReactNode
}

export default function Movable(props: MovableProps) {
  const { id, state: { x, y, z } } = props
  const offset = props.offset ?? 0
  const cardRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const withFocus = props.keyboard$ !== undefined
    const mouseCleanup = addMouseEventListener(id, cardRef.current!, withFocus, props.mouse$, props.targetSelector)

    let keyboardCleanup = () => {}
    if (props.keyboard$) {
      keyboardCleanup = addKeyboardEventListener(id, cardRef.current!, props.keyboard$)
    }

    return () => {
      mouseCleanup()
      keyboardCleanup()
    }
  }, [id, props.mouse$, props.keyboard$, props.targetSelector])

  return (
    <div tabIndex={ 0 } className='movable' ref={ cardRef } style={{ left: x + offset, top: y + offset, zIndex: z }} >
      { props.children }
    </div>
  )
}
