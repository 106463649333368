import './card.css';
import { CardData, DeckData } from 'common';
import StaticCard from '../StaticCard';
import Movable from '../Movable';
import globalSubjects from '../../streams/globalSubjects';
import { LocalActionHandlers } from '../../Pages/PlayArea/actionHandlers';
import { useCallback, useState } from 'react';
import GameCard from '../GameCard';
import { useOptions } from '../Options/atom';

interface CardProps {
  card: CardData,
  deck?: DeckData,
  showFlipped: boolean,
  selected: boolean,
  actions: LocalActionHandlers
}

export function shouldShowFlipped(flipped: boolean, turnable: boolean, myHandId?: number, cardHandId?: number): boolean {
  const ownedByMe = myHandId !== undefined && myHandId === cardHandId
  return !ownedByMe && (cardHandId !== undefined || flipped) && turnable
}

export default function Card(props: CardProps) {
  const [showCardCount, setShowCardCount] = useState(false)
  const useShadows = useOptions().current.t_useShadows.value
  const onMouseEnter = (_: any) => setShowCardCount(true)
  const onMouseLeave = (_: any) => setShowCardCount(false)
  const targetSelector = useCallback((_: HTMLElement) => _.parentElement, [])

  const deckBG = props.deck === undefined ? null : (
    <div style={{ position: 'absolute', left: -3, top: -3, zIndex: -1}} >
      <StaticCard
        dimensions={ props.card.dimensions }
        layout={ props.card.layout }
        rotation={ props.card.state.rotation }
        focused={ props.card.state.focused }
        flipped={ props.showFlipped }
        useShadows={ false }
        selected={ props.selected }
      />
    </div>
  )

  const cardCountUI = props.deck === undefined || !showCardCount ? null : (
    <div style={{ position: 'absolute', left: 0, top: -25, zIndex: 0, display: 'inline-flex', userSelect: "none" }}>
      <div style={{paddingLeft:'5px', paddingRight:'5px', backgroundColor:'lightgray'}}>&lt;{ props.deck.cards.length }&gt;</div>
    </div>
  )

  return (
    <Movable
      id={ props.card.id }
      offset={ useShadows && props.card.state.focused ? -3 : 0 }
      state={ props.card.state }
      mouse$={ globalSubjects.playAreaMouseCard$ }
      keyboard$={ globalSubjects.playAreaKeyboardCard$ }
      targetSelector={ targetSelector }
    >
      <GameCard
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        dimensions={props.card.dimensions}
        layout={props.card.layout}
        rotation={props.card.state.rotation}
        focused={props.card.state.focused}
        flipped={props.showFlipped}
        selected={props.selected}
        text={props.card.layout.frontText}
        textStyle={props.card.layout.frontTextLayout}
        useShadows={ useShadows }
      />
      { cardCountUI }
      { deckBG }
    </Movable>
  )
}
