interface ICard {
  url: string,
  count: number,
  onPlus: () => void,
  onMinus: () => void
}

// TODO: use static card here as well?
export default function Card(props: ICard) {
  return (
    <div style={{ display: 'inline-block', marginBottom: 20, padding: 5 }}>
      <div style={{ backgroundImage: `url(${props.url})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: 70 }} />
      <div>count: { props.count }</div>
      <div>
        <button onClick={ props.onPlus }>+</button>
        <button onClick={ props.onMinus }>-</button>
      </div>
    </div>
  )
}
