export function zip<T,K>(a: T[], b: K[]): [T, K][] {
  return Array.from(Array(Math.max(b.length, a.length)), (_, i) => [a[i], b[i]])
}

export function clampPos(x: number, y: number, min: number = 0): { clampX: number, clampY: number } {
  return {
    clampX: Math.max(min, x),
    clampY: Math.max(0, y)
  }
}

export default function groupIntoSubLists<T>(list: T[], size: number): T[][] {
  return Array.from({length: Math.floor(list.length / size)}, () => list.splice(0, size));
}
