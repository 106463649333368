import { useMemo } from "react";
import { filter, fromEvent, map, merge, Observable, switchMap, take, takeUntil } from "rxjs";
import { CommonEvent } from "../../api/CommonEvent";
import { toCommonEvent } from "../../streams/utilities";

export interface SelectionStream {
  selectionStart$: Observable<CommonEvent>,
  selectionMove$: Observable<CommonEvent>,
  selectionEnd$: Observable<any>
  deselect$: Observable<any>
}

export default function useSelectionStream(): SelectionStream {
  return useMemo(() => {
    const selectionEnd$ = merge(fromEvent(document, 'mouseup'), fromEvent(document, 'touchend'))
    const mouse$: Observable<CommonEvent> = fromEvent(document, 'mousedown').pipe(
      filter(_ => (_.target as HTMLElement).parentNode === document),
      map(e => toCommonEvent(e as MouseEvent))
    )
    const touch$: Observable<CommonEvent> = fromEvent(document, 'touchstart', { passive: false }).pipe(
      filter(_ => (_.target as HTMLElement).parentNode === document),
      switchMap(_ => {
        const start = Date.now()
        return fromEvent(document, 'touchmove', { passive: false }, e => {
          e.preventDefault()
          return toCommonEvent(e as TouchEvent)
        }).pipe(
          take(1),
          takeUntil(selectionEnd$),
          filter(_ => Date.now() - start > 100)
        )
      })
    )
    const selectionStart$ = merge(mouse$, touch$)
    const selectionMove$ = selectionStart$.pipe(
      switchMap(_ => merge(
        fromEvent(document, 'mousemove').pipe(map(_ => toCommonEvent(_ as MouseEvent))),
        fromEvent(document, 'touchmove', { passive: false }, e => {
          e.preventDefault()
          return toCommonEvent(e as TouchEvent)
        })
      ).pipe(takeUntil(selectionEnd$)))
    )

    const deselect$ = selectionEnd$.pipe(
      filter(_ => (_.target as HTMLElement).parentNode === document)
    )

    return {
      selectionStart$,
      selectionMove$,
      selectionEnd$,
      deselect$
    }
  }, []) 
}
