import { useRecoilState } from "recoil"
import { defaultOptions, OptionsValue } from "../../api/OptionsData"
import optionsAtom from "./atom"

export default function Options() {
  const [options, setOptions] = useRecoilState(optionsAtom)
  if (!options.showWindow) {
    return null
  }

  const numberField = (key: string, option: OptionsValue<number>) => (
    <input
      type="number"
      value={ option.value }
      onChange={ _ => setOptions({ ...options, [key]: { description: option.description, value: parseInt(_.target.value) } }) }
    />
  )

  const textField = (key: string, option: OptionsValue<string>) => (
    <input
      type="text"
      value={ option.value }
      onChange={ _ => setOptions({ ...options, [key]: { description: option.description, value: _.target.value.toLowerCase() } }) }
    />
  )

  const toggleField = (key: string, option: OptionsValue<boolean>) => (
    <input
      type="checkbox"
      checked={ option.value }
      onChange={ _ => setOptions({ ...options, [key]: { description: option.description, value: _.target.checked } }) }
    />
  )

  return (
    <div className="deckViewContainer" style={{ width: 360, height: 490 }}>
      <span style={{ fontSize: 30 }}>Options</span>
      <div>
        <button onClick={ () => setOptions({ ...defaultOptions, showWindow: true }) }>Set Defaults</button>
        <button onClick={ () => setOptions({ ...options, showWindow: false }) }>Save and Close</button>
        <table style={{ paddingTop: 10 }}>
          <tbody>
          {
            Object.entries(options).map(([key, option]) => {
              let input = null
              if (key.startsWith('m_')) {
                input = numberField(key, option)
              }
              else if (key.startsWith('k_')) {
                input = textField(key, option)
              }
              else if (key.startsWith('t_')) {
                input = toggleField(key, option)
              }
              else {
                return null
              }
              
              return (
                <tr key={ key }>
                  <td>{ (option as OptionsValue<any>).description }</td>
                  <td>{ input }</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}
