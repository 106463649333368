import { animationFrameScheduler, defer, from, interval, Observable, of, Subject } from "rxjs";
import { buffer, concatMap, debounceTime, map, takeWhile, throttleTime } from "rxjs/operators";
import { WebSocketStreams } from "../api/WebSocketStreams";
import { moveTo, batchMoveTo, PlayAreaActionTypes, BATCH_MOVE_TO, MOVE_TO } from "common";

export default function createWebSocketStreams(wsIn$: Subject<PlayAreaActionTypes[]>, wsOut$: Subject<PlayAreaActionTypes[]>): WebSocketStreams {

  const scheduler = animationFrameScheduler
  const msElapsed = defer(() => {
    const start = scheduler.now()
    return interval(0, scheduler).pipe(
      map(_ => scheduler.now() - start)
    )
  })
  const duration = (ms: number) => msElapsed.pipe(
    map(ems => ems / ms),
    takeWhile(t => t <= 1)
  )

  function createInStream(): Observable<PlayAreaActionTypes> {
    return wsIn$.pipe(
      concatMap(_ => from(_)),
      concatMap(e => {
        switch (e.type) {
          case BATCH_MOVE_TO:
            const [ dx, dy ] = [ e.toX - e.fromX, e.toY - e.fromY ]
            if (dx === 0 && dy === 0) { // grid layout
              return of(moveTo(e.kind, e.id, e.toX, e.toY, e.selection))
            }

            return duration(250).pipe(
              map(t => Math.sin(t * Math.PI/2)),
              map(frame => moveTo(e.kind, e.id, e.fromX + dx * frame, e.fromY + dy * frame, e.selection))
            )
            
          default:
            return of(e)
        }
      })
    )
  }

  function createOutStream(): Subject<PlayAreaActionTypes> {
    const out$ = new Subject<PlayAreaActionTypes>()
    out$.pipe(
      buffer(out$.pipe(throttleTime(300), debounceTime(300))),
      map(buffer => buffer.reduce<PlayAreaActionTypes[]>((acc, curr) => {
        const lastItem = acc.length > 0 ? acc[acc.length - 1] : undefined
        // TODO: too much rotate event slows the interpolation down on the other side
        switch (curr.type) {
          case MOVE_TO:
            if (lastItem !== undefined && lastItem.type === BATCH_MOVE_TO && lastItem.kind === curr.kind && lastItem.id === curr.id) {
              lastItem.toX = curr.x
              lastItem.toY = curr.y
            } else {
              acc.push(batchMoveTo(curr.kind, curr.id, curr.x, curr.y, curr.x, curr.y, curr.selection))
            }
            return acc

          default:
            acc.push(curr)
            return acc
        }
      }, []))
    ).subscribe(_ => wsOut$.next(_))

    return out$
  }

  return {
    wsIn$: createInStream(),
    wsOut$: createOutStream()
  }

}
