"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOGGLE_TURN_MARKER = exports.HAND_COLOR = exports.FOCUS_CARD = exports.LOCK_CARD = exports.DRAW_FROM_POUCH = exports.DELETE_HAND = exports.ADD_HAND = exports.ROLL_DIE = exports.SYNC_STATE_REQ = exports.SYNC_STATE = exports.SELECT_GAME = exports.ROTATE_CARD = exports.SHUFFLE_HAND = exports.SHUFFLE_DECK = exports.TOGGLE_HAND = exports.CARD_HAND_ORDER = exports.CARD_DISOWN = exports.CARD_OWNER = exports.MERGE_DECK = exports.REMOVE_FROM_DECK = exports.PLACE_IN_DECK = exports.CREATE_DECK = exports.FLIP_DECK = exports.FLIP_CARD = exports.MOVE_CARD_TO_TOP = exports.MOVE_KIND = exports.BATCH_MOVE_TO = exports.MOVE_TO = void 0;
exports.MOVE_TO = 'MOVE_TO';
exports.BATCH_MOVE_TO = 'BATCH_MOVE_TO';
var MOVE_KIND;
(function (MOVE_KIND) {
    MOVE_KIND[MOVE_KIND["CARD"] = 0] = "CARD";
    MOVE_KIND[MOVE_KIND["DECK"] = 1] = "DECK";
    MOVE_KIND[MOVE_KIND["HAND"] = 2] = "HAND";
    MOVE_KIND[MOVE_KIND["DIE"] = 3] = "DIE";
    MOVE_KIND[MOVE_KIND["POUCH"] = 4] = "POUCH";
})(MOVE_KIND = exports.MOVE_KIND || (exports.MOVE_KIND = {}));
exports.MOVE_CARD_TO_TOP = 'MOVE_CARD_TO_TOP';
exports.FLIP_CARD = 'FLIP_CARD';
exports.FLIP_DECK = 'FLIP_DECK';
exports.CREATE_DECK = 'CREATE_DECK';
exports.PLACE_IN_DECK = 'PLACE_IN_DECK';
exports.REMOVE_FROM_DECK = 'REMOVE_FROM_DECK';
exports.MERGE_DECK = 'MERGE_DECK';
exports.CARD_OWNER = 'CARD_OWNER';
exports.CARD_DISOWN = 'CARD_DISOWN';
exports.CARD_HAND_ORDER = 'CARD_HAND_ORDER';
exports.TOGGLE_HAND = 'TOGGLE_HAND';
exports.SHUFFLE_DECK = 'SHUFFLE_DECK';
exports.SHUFFLE_HAND = 'SHUFFLE_HAND';
exports.ROTATE_CARD = 'ROTATE_CARD';
exports.SELECT_GAME = 'SELECT_GAME';
exports.SYNC_STATE = 'SYNC_STATE';
exports.SYNC_STATE_REQ = 'SYNC_STATE_REQ';
exports.ROLL_DIE = 'ROLL_DIE';
exports.ADD_HAND = 'ADD_HAND';
exports.DELETE_HAND = 'DELETE_HAND';
exports.DRAW_FROM_POUCH = 'DRAW_FROM_POUCH';
exports.LOCK_CARD = 'LOCK_CARD';
exports.FOCUS_CARD = 'FOCUS_CARD';
exports.HAND_COLOR = 'HAND_COLOR';
exports.TOGGLE_TURN_MARKER = 'TOGGLE_TURN_MARKER';
