"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleTurnMarker = exports.handColor = exports.focusCard = exports.lockCard = exports.drawFromPouch = exports.deleteHand = exports.addHand = exports.rollDie = exports.syncStateReq = exports.syncState = exports.selectGame = exports.rotateCard = exports.toggleHandOwner = exports.cardHandOrder = exports.cardDisown = exports.cardOwner = exports.shuffleHand = exports.shuffleDeck = exports.mergeDeck = exports.removeFromDeck = exports.placeInDeck = exports.createDeck = exports.flipDeck = exports.flipCard = exports.moveCardToTop = exports.batchMoveTo = exports.moveTo = void 0;
var T = __importStar(require("./playAreaActionTypes"));
function moveTo(kind, id, x, y, selection) {
    return { type: T.MOVE_TO, kind: kind, id: id, selection: selection, x: x, y: y };
}
exports.moveTo = moveTo;
function batchMoveTo(kind, id, fromX, fromY, toX, toY, selection) {
    return { type: T.BATCH_MOVE_TO, kind: kind, id: id, selection: selection, fromX: fromX, fromY: fromY, toX: toX, toY: toY };
}
exports.batchMoveTo = batchMoveTo;
function moveCardToTop(id) {
    return { type: T.MOVE_CARD_TO_TOP, id: id };
}
exports.moveCardToTop = moveCardToTop;
function flipCard(ids, flipped) {
    return { type: T.FLIP_CARD, ids: ids, flipped: flipped };
}
exports.flipCard = flipCard;
function flipDeck(id, flipped) {
    return { type: T.FLIP_DECK, id: id, flipped: flipped };
}
exports.flipDeck = flipDeck;
function createDeck() {
    var cardIds = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        cardIds[_i] = arguments[_i];
    }
    return { type: T.CREATE_DECK, cardIds: cardIds };
}
exports.createDeck = createDeck;
function placeInDeck(cardId, deckId) {
    return { type: T.PLACE_IN_DECK, cardId: cardId, deckId: deckId };
}
exports.placeInDeck = placeInDeck;
function removeFromDeck(id) {
    return { type: T.REMOVE_FROM_DECK, id: id };
}
exports.removeFromDeck = removeFromDeck;
function mergeDeck(deckId, targetCardId) {
    return { type: T.MERGE_DECK, deckId: deckId, targetCardId: targetCardId };
}
exports.mergeDeck = mergeDeck;
function shuffleDeck(cardOrder) {
    return { type: T.SHUFFLE_DECK, cardOrder: cardOrder };
}
exports.shuffleDeck = shuffleDeck;
function shuffleHand(cardOrder) {
    return { type: T.SHUFFLE_HAND, cardOrder: cardOrder };
}
exports.shuffleHand = shuffleHand;
function cardOwner(cardId, handId) {
    return { type: T.CARD_OWNER, cardId: cardId, handId: handId };
}
exports.cardOwner = cardOwner;
function cardDisown(cardId) {
    return { type: T.CARD_DISOWN, cardId: cardId };
}
exports.cardDisown = cardDisown;
function cardHandOrder(cardId) {
    return { type: T.CARD_HAND_ORDER, cardId: cardId };
}
exports.cardHandOrder = cardHandOrder;
function toggleHandOwner(id, name) {
    return { type: T.TOGGLE_HAND, id: id, name: name };
}
exports.toggleHandOwner = toggleHandOwner;
function rotateCard(id, rotation) {
    return { type: T.ROTATE_CARD, id: id, rotation: rotation };
}
exports.rotateCard = rotateCard;
function selectGame(config) {
    return { type: T.SELECT_GAME, config: config };
}
exports.selectGame = selectGame;
function syncState(state) {
    return { type: T.SYNC_STATE, state: state };
}
exports.syncState = syncState;
function syncStateReq() {
    return { type: T.SYNC_STATE_REQ };
}
exports.syncStateReq = syncStateReq;
function rollDie(id, roll) {
    return { type: T.ROLL_DIE, id: id, roll: roll };
}
exports.rollDie = rollDie;
function addHand(id, name, x, y) {
    return { type: T.ADD_HAND, id: id, name: name, x: x, y: y };
}
exports.addHand = addHand;
function deleteHand(id) {
    return { type: T.DELETE_HAND, id: id };
}
exports.deleteHand = deleteHand;
function drawFromPouch(pouchId, newId, x, y) {
    return { type: T.DRAW_FROM_POUCH, pouchId: pouchId, newId: newId, x: x, y: y };
}
exports.drawFromPouch = drawFromPouch;
function lockCard(cardId, state) {
    return { type: T.LOCK_CARD, cardId: cardId, state: state };
}
exports.lockCard = lockCard;
function focusCard(ids, state) {
    return { type: T.FOCUS_CARD, ids: ids, state: state };
}
exports.focusCard = focusCard;
function handColor(id, color) {
    return { type: T.HAND_COLOR, id: id, color: color };
}
exports.handColor = handColor;
function toggleTurnMarker(toggle) {
    return { type: T.TOGGLE_TURN_MARKER, toggle: toggle };
}
exports.toggleTurnMarker = toggleTurnMarker;
