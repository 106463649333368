import { LocalActionHandlers } from "../../Pages/PlayArea/actionHandlers"

export type ContextMenuItem = {
  text: string,
  close: boolean,
  action: () => void
}

export type ContextMenuGroup = {
  title: string,
  items: ContextMenuItem[]
}

export function cardContextGroup(id: number, actions: LocalActionHandlers): ContextMenuGroup {
  return ({
    title: 'Card actions',
    items: [
      { text: 'Zoom', close: true, action: () => actions.onZoom(id) },
      { text: 'Lock / Unlock', close: true, action: () => actions.onLock(id) },
      { text: 'Rotate -10', close: false, action: () => actions.onRotate(id, -10) },
      { text: 'Rotate +90', close: false, action: () => actions.onRotate(id, 90) },
      { text: 'Rotate +10', close: false, action: () => actions.onRotate(id, 10) }
    ]
  })
}

export function cardSelectionContextGroup(ids: number[], actions: LocalActionHandlers): ContextMenuGroup {
  return ({
    title: 'Card selection actions',
    items: [
      { text: 'Group', close: true, action: () => actions.onGroup(ids) },
      { text: 'Shuffle', close: false, action: () => actions.onCardSelectionShuffle(ids) }
    ]
  })
}

export function deckContextGroup(id: number, actions: LocalActionHandlers): ContextMenuGroup {
  return ({
    title: 'Deck actions',
    items: [
      { text: 'Shuffle', close: false, action: () => actions.onShuffleDeck(id) },
      { text: 'Show', close: true, action: () => actions.onShowDeck(id) },
      { text: 'Deal', close: false, action: () => actions.onDeal(id) },
      { text: 'Grid', close: true, action: () => {
        const dim = prompt('Grid dimensions', '6x3x3') ?? ''
        if (dim.trim().length === 0) {
          alert('Invalid dimension')
          return
        }
        const [cols, rows, count] = dim.split('x')
        actions.onGrid(id, parseInt(cols), parseInt(rows), parseInt(count))
      }},
      { text: 'Sort Ascending', close: true, action: () => actions.onSort(id, true) },
      { text: 'Sort Descending', close: true, action: () => actions.onSort(id, false) }
    ]
  })
}

export function handContextGroup(id: number, actions: LocalActionHandlers): ContextMenuGroup {
  const pickHandColor = () => {
    const color = prompt('Color', 'blue')
    if (color === null) {
      return
    }
    actions.onHandColor(id, color)
  }

  return ({
    title: 'Hand actions',
    items: [
      { text: 'Clear owners', close: true, action: () => actions.unSeat(id) },
      { text: 'Delete', close: true, action: () => actions.onDeleteHand(id) },
      { text: 'Color', close: true, action: pickHandColor }
    ]
  })
}
