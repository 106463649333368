import { atom, atomFamily, selectorFamily } from "recoil"
import { ItemDimensions } from "common"
import optionsAtom from "../Options/atom"

export const positionState = atom({
  key: 'zoomWindowPosition',
  default: { x: 50, y: 50 }
})

const uniformScaleState = atom({
  key: 'zoomWindowScale',
  default: 3
})

export const scalesState = atomFamily<number, ItemDimensions>({
  key: 'zoomWindowScales',
  default: 3
})

export const scalesSelector = selectorFamily<number, ItemDimensions>({
  key: 'zoomWindowDimension',
  get: dimension => ({get}) => {
    if (get(optionsAtom).t_uniformScale.value) {
      return get(uniformScaleState)
    }
    return get(scalesState(dimension))
  },
  set: dimension => ({get, set}, newValue) => {
    const scale = newValue as number
    if (get(optionsAtom).t_uniformScale.value) {
      set(uniformScaleState, scale)
    }
    set(scalesState(dimension), scale)
  }
})
