import { CSSProperties, MutableRefObject, useEffect, useRef, useState } from "react";
import { Subscription } from "rxjs";
import { CommonEvent } from "../../api/CommonEvent";
import { PlayAreaState } from "common";
import { isOverlapping } from "../../streams/utilities";
import useSelectionStream from "./useSelectionStream";
import "./style.css";

interface Point {
  x: number,
  y: number
}

interface SelectionState {
  cursorStart?: Point,
  cursorEnd?: Point,
  drawStart?: Point,
  drawEnd?: Point
}

interface SelectionProps {
  playAreaState: MutableRefObject<PlayAreaState>
  onSelected: (cards: number[], decks: number[], dice: number[]) => void
}

export default function Selection(props: SelectionProps) {
  const { playAreaState, onSelected } = props
  const [state, setState] = useState<SelectionState>({})
  const stateRef = useRef(state)
  stateRef.current = state

  const { selectionStart$, selectionMove$, selectionEnd$, deselect$ } = useSelectionStream()
  useEffect(() => {
    const onSelectionStart = ({ pageX, pageY }: CommonEvent) => setState({
      cursorStart: { x: pageX, y: pageY },
      cursorEnd: undefined,
      drawStart: { x: pageX, y: pageY },
      drawEnd: undefined
    })

    const onSelectionMove = ({ pageX, pageY }: CommonEvent) => setState(oldState => {
      const { x: startX, y: startY } = oldState.cursorStart!
      const [left, right] = [startX, pageX].sort((a, b) => a-b)
      const [top, bottom] = [startY, pageY].sort((a, b) => a-b)

      return {
        cursorStart: oldState.cursorStart,
        cursorEnd: { x: pageX, y: pageY },
        drawStart: { x: left + 1, y: top + 1 },
        drawEnd: { x: right, y: bottom }
      }
    })

    const onSelectionEnd = (_: any) => {
      const { drawStart, drawEnd } = stateRef.current
      if (drawStart === undefined || drawEnd === undefined) {
        return
      }

      const { cards, decks } = Object.values(playAreaState.current.cards)
        .filter(card => card.state.visible)
        .filter(card => {
          const [ left, right, top, bottom ] = [ card.state.x, card.state.x + card.dimensions.width, card.state.y, card.state.y + card.dimensions.height ]
          return isOverlapping(drawStart.x, drawStart.y, drawEnd.x, drawEnd.y, left, top, right, bottom)
        })
        .reduce<{ cards: number[], decks: number[] }>((acc, item) => {
          if (item.state.deckId !== undefined) {
            acc.decks.push(item.state.deckId)
          } else {
            acc.cards.push(item.id)
          }
          return acc
        }, { cards: [], decks: [] })

      const dice = playAreaState.current.dice
        .filter(die => {
          const [ left, right, top, bottom ] = [ die.state.x, die.state.x + die.dimensions.width, die.state.y, die.state.y + die.dimensions.height ]
          return isOverlapping(drawStart.x, drawStart.y, drawEnd.x, drawEnd.y, left, top, right, bottom)
        })
        .map(die => die.id)

      onSelected(cards, decks, dice)

      setState({
        cursorStart: undefined,
        cursorEnd: undefined,
        drawStart: undefined,
        drawEnd: undefined
      })
    }

    const onDeselect = (_: any) => {
      if (stateRef.current.cursorEnd === undefined) {
        onSelected([], [], [])
      }
    }

    const subs: Subscription[] = [
      deselect$.subscribe(onDeselect), // must come before other handlers
      selectionStart$.subscribe(onSelectionStart),
      selectionMove$.subscribe(onSelectionMove),
      selectionEnd$.subscribe(onSelectionEnd),
    ]
    return () => subs.forEach(s => s.unsubscribe())
  }, [playAreaState, onSelected, selectionStart$, selectionMove$, selectionEnd$, deselect$])

  const { drawStart, drawEnd } = state
  const showSelectionBox = drawStart !== undefined && drawEnd !== undefined
  const style: CSSProperties | undefined = !showSelectionBox ? undefined : {
    top: drawStart.y,
    left: drawStart.x,
    width: drawEnd.x - drawStart.x,
    height: drawEnd.y - drawStart.y
  }

  return !showSelectionBox ? null : <div className="selectionBox" style={ style }/>
}
