import { Subject } from "rxjs";
import { KeyboardPlayAreaEvent, PlayAreaEvent } from "../api/PlayAreaEvent";

const globalSubjects = {
  playAreaMouseCard$: new Subject<PlayAreaEvent>(),
  playAreaKeyboardCard$: new Subject<KeyboardPlayAreaEvent>(),
  playAreaHand$: new Subject<PlayAreaEvent>(),
  playAreaDie$: new Subject<PlayAreaEvent>(),
  playAreaPouch$: new Subject<PlayAreaEvent>()
}

export default globalSubjects
